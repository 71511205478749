 // @ts-nocheck
import React, { useMemo ,useState,useEffect} from 'react'
import { Card as UikitCard, Flex, Text ,Radio, ChartIcon} from '@hurricaneswap/uikit'
import axios from "axios"
import { Currency } from '@glhf-libs/sdk'
import styled from 'styled-components'
import useMobile from '../../hooks/useMobile'
import CurrencyLogo from '../CurrencyLogo'
import { MenuItem } from '../SearchModal/styleds'
import { useAllTokens } from '../../hooks/Tokens'
import { useSelectedTokenList} from '../../state/lists/hooks'
import {config} from './config'

const Container = styled.div<{ifMobile: boolean}>`
  max-width: 710px;
  width: 100%;
  padding: ${({ifMobile})=>(ifMobile ? '0 10px' : '0')};
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
`

const Card = styled(UikitCard)<{fontSize: any}>`
  background: ${({theme})=>(theme.colors.swapContainer)};
  border:${ ({ theme }) => (theme.isDark ? '1px solid #ffffff47' : 'none') }; 
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding: 16px 0px 16px 20px;
  min-height: 166px;
  max-height: 205px;
  width: 100%;
  max-width: 228px;
  font-size: ${({fontSize})=>(fontSize)};
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 228px));
  // grid-template-columns: repeat(2, minmax(228px, 50%));
  grid-gap: 16px 13px;
  justify-content: center;
  
  @media (max-width: 510px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
`
// ETH UNI CAKE MDX LINK EPS
const pairs = [
  {name: 'ETH/USDT', TVL: '-', VOL: '-', APY: '-'},
  {name: 'UNI/USDT', TVL: '-', VOL: '-', APY: '-'},
  {name: 'CAKE/USDT', TVL: '-', VOL: '-', APY: '-'},
  {name: 'MDX/USDT',  TVL: '-', VOL: '-', APY: '-'},
  {name: 'LINK/USDT', TVL: '-', VOL: '-', APY: '-'},
  {name: 'EPS/USDT', TVL: '-', VOL: '-', APY: '-'}
]


const StationTradingPairs = () => {
  const ifMobile = useMobile()
  const allTokens = useAllTokens()
  const allChainTokens = useSelectedTokenList()
  const [choose,setChoose] = useState('bsc')
  const [pairsToShow,setPairsToShow] = useState({})

  const USDT = useMemo(()=>{
    const u = Object.values(allTokens).filter(i=>i?.symbol ? (i?.symbol.toUpperCase() === 'USDT' || i?.symbol.toUpperCase() === 'AUSDT') : false)
    if(u && u.length>0){
      return u[0]
    }
    return Currency.ETHER
  }, [allTokens])


  const getPairInfo= async () => {
    // const res = await axios.post("https://api.thegraph.com/subgraphs/name/peratx/hurricane-exchange-subgraph-fuji", {
    const res = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange-v2-fuji", {
      query: `{
        pairs(where:{crossPair:true}){
          id
          reserveUSD
          volumeUSD
          crossPair
          name
          token0{
            id
          }
        }
      }`,
      variables: null
    });
    const data = {}
    if (res?.data?.data?.pairs) {
      res.data.data.pairs.forEach((pair => { data[pair.token0.id]={'tvl':pair.reserveUSD,'vol':pair.volumeUSD}}))
      }
    return data
  };
  

  const pairTokens: any =  useMemo(() => {
    const cardPairs = (chain) =>{
  
      const pairValues = {}

      config.forEach((item) =>{
        if (item.chainId === chain){
        const tokens= allChainTokens[item.chainId]
        // @ts-ignore
        if (tokens[item.token] !== undefined){
          pairValues[tokens[item.token].tokenInfo.to.toLowerCase()] = {token:tokens[item.token],TVL:0,VOL:0,APY:0}
          }
        }
         // @ts-ignore
      })
      return pairValues
    }
    let u
      if (choose === 'all'){
         u = { ...cardPairs('97'), ...cardPairs('256')}
      } else if(choose === 'bsc'){
         u = cardPairs('97')
      } else if (choose === 'heco'){
        u = cardPairs('256')
      }
      return u
  }, [choose,allChainTokens])


  useEffect(() => {

   getPairInfo().then(
     (datas) =>{
       // eslint-disable-next-line 
        for (const i of Object.keys(datas)){
          pairTokens[i.toLowerCase()].VOL = parseInt(datas[i].vol).toLocaleString()
          pairTokens[i.toLowerCase()].TVL = parseInt(datas[i].tvl).toLocaleString()
          pairTokens[i.toLowerCase()].FEE = parseInt(datas[i].vol * 0.003).toLocaleString()
        }
        setPairsToShow(pairTokens)
     }
   ).catch((error) => {
    // console.error(error);
    setPairsToShow(pairTokens)
  })
  }, [pairTokens])  


  const allowTokens: any =  useMemo(()=>{
    const u = Object.values(allTokens).filter(i=>i?.symbol ? (i?.symbol.toUpperCase() !== 'USDT' && i?.symbol.toUpperCase() !== 'AUSDT') : false)
    if(u && u.length>0){
      return u
    }
    return [Currency.ETHER]
  }, [allTokens])


  return (
    <Container ifMobile={ifMobile}>
      <Flex flexDirection='row' flexWrap='wrap'>
        <Text fontSize='20px' mb='20px' lineHeight='20px' fontWeight='600' mr='50px'>
          Station Trading Pairs
        </Text>

        <Flex  flexDirection='row'>
        <Radio scale="sm"  value ='all' onChange={()=> setChoose('all')} checked={ choose === 'all'}/>
        <Text fontSize='16px' mb='20px' mr='25px' ml='8px'>ALL</Text>
        <Radio scale="sm"  value='bsc' onChange={()=>setChoose('bsc')} checked={ choose === 'bsc'}/>
        <Text fontSize='16px' mb='20px' mr='25px' ml='8px'>BSC</Text>
       {/* <Radio scale="sm"  value ='all' onChange={()=> setChoose('heco')} checked={ choose === 'heco'}/>
        <Text fontSize='16px' mb='20px' mr='25px' ml='8px'>HECO</Text> */}
        </Flex>

      </Flex>

      <Grid>
        {Object.values(pairsToShow).map(i=>(
          <Card key={i.token.name} fontSize='16px'>
            <Flex flexDirection='column' p='16px 20px'>
              <Flex maxWidth='170px' flexWrap='wrap' flexDirection='row' alignItems='center'>
                <Flex mb='17px' flexDirection='row' alignItems='center'>
                  <CurrencyLogo style={{position: 'relative', zIndex: 1}} currency={i.token} size="1.5em" />
                  <CurrencyLogo style={{transform: 'translate3d(-10px, 0, 0)', position: 'relative', zIndex: 0}} currency={USDT} size="1.5em" />
                </Flex>
                <Text mb='17px' fontSize='1em'>{i.token.symbol}/{USDT?.symbol}</Text>
              </Flex>
              <Text fontSize='1em'>TVL: ${i?.TVL || '-'}</Text>
              <Text fontSize='1em' mt='10px' mb='10px'>VOL: ${i?.VOL || '-'}</Text>
              <Text fontSize='1em'>FEE: ${i?.FEE || '-'}</Text>
            </Flex>
          </Card>
          ))}
      </Grid>


    </Container>

  )
}

export default StationTradingPairs
