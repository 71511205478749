import React, { useContext } from 'react'
import styled from 'styled-components'
import {Flex,  TwitterIcon,  TelegramIcon,  MediumIcon} from '@hurricaneswap/uikit'
import {AiOutlineTwitter, AiOutlineMedium} from 'react-icons/ai'
import { Link, NavLink } from 'react-router-dom'
import { isConstructorDeclaration } from 'typescript'
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../hooks/LanguageContext'
import { EN} from '../../constants/localisation/languageCodes'
import i18next from '../../i18n'
import useMobile from '../../hooks/useMobile'
import GitIcon from '../../assets/imgs/github.svg'



const Socialflex = styled (Flex)`
  justify-content:left;
  margin-top:10px;
 svg {
   margin-right:20px
 }
 `

/* const Wrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid rgba(77, 60, 85, 0.12);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  //margin-top: 113px;
` */

const Wrapper = styled.div`

  width: 100vw;
  padding: 25px 220px;
  // border-top: 1px solid rgba(77, 60, 85, 0.12);
  display: flex;
  height:70px;
  justify-content: space-between;
  flex-direction: column;
  //margin-top: 113px;
  background: ${({theme})=>(theme.isDark ? 'rgba(255, 255, 255, 0.1)':'rgba(255, 255, 255, 0.4)')};
  margin-bottom:-80px
`
const Text = styled(Link)`
  margin-right: 56px;
  color: ${({theme})=>(theme.isDark ? 'rgba(255, 255, 255, 0.6)':'#4D3C55')};
  &:hover{
    color: ${({theme})=>theme.colors.primary};
  };
  font-family: GTText;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
    
  @media (max-width: 510px) {
    margin-right: 24px;
  }
`

const TextLink = styled.a`
  color: ${({theme})=>(theme.isDark ? 'rgba(255, 255, 255, 0.6)':'#4D3C55')};
  &:hover{
    color: ${({theme})=>theme.colors.primary};
  };
  font-family: GTText;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
    
  @media (max-width: 510px) {
    margin-right: 24px;
  }
`

const Privacy = styled.div`
font-family: GTText;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
color: ${({theme})=>(theme.isDark ? 'rgba(255, 255, 255, 0.6)':'#4D3C55')};
`

const getLanguageColor = ({active,theme})=>{
  let color
  if (active && theme.isDark){
    color='rgba(255,255,255,0.85)'
  }
  if (!active && theme.isDark){
    color= 'rgba(255,255,255,0.45)'
  }
  if (active && !theme.isDark){
    color= 'rgba(77, 60, 85, 0.85)'
  }
  if (!active && !theme.isDark){
    color= 'rgba(77, 60, 85, 0.45)'
  }
  return color
}
const Language = styled.div<{active: boolean | undefined,theme}>`
cursor: pointer;
font-family: GTText;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
color: ${getLanguageColor};
`
const HomePageFooter = () => {
  const {t} = useTranslation()
  const ifMobile = useMobile()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  return (
    <Wrapper>
      {
        !ifMobile && (
          <Flex justifyContent='space-around'>
            <Privacy>
            © 2018-2021 Hurricaneswap. All rights reserved.
            </Privacy>
            <Flex justifyContent='space-between' width='300px'>

                <TextLink href="https://hurricaneswap.gitbook.io/hurricaneswap/faq/1.-what-is-hurricaneswap">
                  {t('Help')}
                </TextLink>
                <TextLink href="https://hurricaneswap.medium.com/">
                  {t('Blog')}
                </TextLink>
                    <a href="https://twitter.com/hurricaneswap" aria-label='social'><TwitterIcon/></a>
                    <a href="https://t.me/HurricaneSwap" aria-label='social'><TelegramIcon/></a>
                    <a href="https://hurricaneswap.medium.com" aria-label='social'><MediumIcon/></a>
                   {/* <a href="https://github.com" aria-label='social'><img width='20px' height='20px' alt='git' src={GitIcon}/></a> */}
            </Flex>
          </Flex>
          )
      }
      
    </Wrapper>
  )
}

export default HomePageFooter
