
export const config = [
    {
        token: '0xB4c1E96E648E763D3eB5Cd1BB2EC9c93293dE49',
        data: '',
        chainId: '97'
    },
    {
        token: '0x8EeE49d7368b1FFDD252baAf5099df902FE89d61',
        data: '',
        chainId: '97'
    },
    {
        token: '0xDdaf411a78d804EFd047EC9C2088403Fdb5BeAB9',
        data: '',
        chainId: '97'
    },
    {
        token: '0xE3bCcd2c46da68F3e00C97881Ad75D10992144Eb',
        data: '',
        chainId: '97'
    },
    {
        token: '0x267C3d338B1b7275D8B306baEf007e0BE2fca463',
        data: '',
        chainId: '97'
    },
    {
        token: '0x12B587b9cc0cb621dB7CC2a4a2Bcb4944d1EaCB5',
        data: '',
        chainId: '97'
    },
    {
        token: "0xB4c1E96E648E763D3eB5Cd1BB2EC9c93293dE49f",
        data: '',
        chainId: '97'
    }


];



export default config
