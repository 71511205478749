import React from 'react'
import {Flex} from '@hurricaneswap/uikit'
import styled from 'styled-components'
import useMobile from '../../hooks/useMobile'
import StationDes from '../StationDes'
import StationTradingPairs from '../StationTradingPairs'
import coinGroup from '../../assets/imgs/coinGroup.png'



const Divider = styled.div`
  padding-top: 40px;
  width: 100%
`

const StationTokenInfoTest = () => {
  const ifMobile = useMobile()


  return (
    <Flex flexDirection='column' justifyContent='center' alignItems='center'>
      <StationDes/>

      <Divider/>

      <StationTradingPairs/>
    </Flex>
  )
}

const Icon = styled.div<{ifMobile?: boolean}>`
  background-image: url(${({ifMobile})=>(!ifMobile ? coinGroup : null)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width:453px;
  height:330px;
  margin-top:100px

`
const StationTokenInfoMain = () => {
  return (
    <Icon />
  )
}

// env 配置测试网主网 
const StationTokenInfo = process.env.REACT_APP_ENV === 'MAIN' ? StationTokenInfoMain : StationTokenInfoTest
// const StationTokenInfo = StationTokenInfoMain
export default StationTokenInfo
