
export type CoinCardColor = {
  light: string;
  dark: string;
}

export interface Config {
  symbol: string;
  contract: string;
  title: string;
  currency: string;
  chain: string;
  color: CoinCardColor
  address: string;
  subtitle?: string
}

const TestTokenList: Config[] = [
  {
    title: 'hct',
    symbol: 'hct',
    contract: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
    currency: 'HCTIcon',
    chain: 'avax',
    address: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(217, 203, 255, 0.25) 7.41%, rgba(196, 130, 248, 0.49) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(217, 203, 255, 0.125) 7.41%, rgba(196, 130, 248, 0.245) 89.26%), #23122A'
    }
  },
  {
    title: 'avax',
    symbol: 'WETH',
    contract: '0x66f05c9882535569a29ab045c2e7776266ab5606',
    currency: 'AVAXIcon',
    chain: 'avax',
    address: '0x66f05c9882535569a29ab045c2e7776266ab5606',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.13) 7.41%, rgba(248, 137, 130, 0.35) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.07) 7.41%, rgba(248, 137, 130, 0.175) 89.26%), #2A1219'
    }
  },
  {
    title: 'usdt',
    subtitle: '.e',
    symbol: 'aUSDT',
    contract: '0xC40f16B3DF9b2E11f48B11A535ad9ab55b7dbF67',
    currency: 'USDTIcon',
    chain: 'bsc',
    address: '0xC40f16B3DF9b2E11f48B11A535ad9ab55b7dbF67',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(156, 237, 188, 0.11) 7.41%, rgba(156, 237, 188, 0.46) 89.26%), #FFFFFF;',
      dark: 'linear-gradient(148.3deg, rgba(156, 237, 188, 0.055) 7.41%, rgba(156, 237, 188, 0.23) 89.26%), #122A26'
    }
  },
  {
    title: 'weth',
    symbol: 'aWETH',
    contract: '0x7961192cb1994f8cf9562e745dc3045e6bcddaea', 
    currency: 'ETHIcon',
    chain: 'bsc',
    address: '0x7961192cb1994f8cf9562e745dc3045e6bcddaea',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(203, 213, 255, 0.23) 7.41%, rgba(130, 153, 248, 0.46) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(203, 213, 255, 0.115) 7.41%, rgba(130, 153, 248, 0.23) 89.26%), #101216'
    }
  },
  {
    title: 'cake',
    symbol: 'aCAKE',
    contract: '0xBFCdA28A78d74E78CE06cABDC0b27124AC5f4268', 
    currency: 'CAKEIcon',
    chain: 'bsc',
    address: '0xBFCdA28A78d74E78CE06cABDC0b27124AC5f4268',
    color:
    {
      light: 'linear-gradient(152.21deg, #FFFEF9 10.62%, #FBE6BC 95.44%), #FFFFFF;',
      dark: 'linear-gradient(152.21deg, rgba(255, 254, 249, 0.5) 10.62%, rgba(251, 230, 188, 0.5) 95.44%), #211D09'
    }
  },
  {
    title: 'mdx',
    symbol: 'aMDEX',
    contract: '0x98556f4bf9657Ec3b42b703405be4292D627aDfD',
    currency: 'MDXIcon',
    chain: 'bsc',
    address: '0x98556f4bf9657Ec3b42b703405be4292D627aDfD',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(115, 194, 232, 0.12) 7.41%, rgba(114, 192, 230, 0.21) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(115, 194, 232, 0.06) 7.41%, rgba(114, 192, 230, 0.105) 89.26%), #101216;'
    }
  },

  {
    title: 'xvs',
    symbol: 'xvs',
    contract: '0x4B16917b198A22cFa875A08ebe359824874A9c28',
    currency: 'XVSIcon',
    chain: 'bsc',
    address: '0x4B16917b198A22cFa875A08ebe359824874A9c28',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.13) 7.41%, rgba(248, 137, 130, 0.35) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.065) 7.41%, rgba(248, 137, 130, 0.175) 89.26%), #23122A'
    }
  },
  {
    title: 'bnb',  
    symbol: 'aUSDT',
    contract: '0x31EF1216757e33490D33183b5592CCc2E4b74E01',
    currency: 'BNBIcon',
    chain: 'bsc',
    address: '0x31EF1216757e33490D33183b5592CCc2E4b74E01',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(255, 245, 221, 0.4) 7.41%, rgba(255, 238, 198, 0.6) 89.26%), #FFFFFF;',
      dark: 'linear-gradient(148.3deg, rgba(255, 245, 221, 0.2) 7.41%, rgba(255, 238, 198, 0.3) 89.26%), #282A12;'
    }
  }

];



const Maintokenlist: Config[] = [
  {
    title: 'hct',
    symbol: 'hct',
    contract: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd',
    currency: 'HCTIcon',
    chain: 'avax',
    address: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(217, 203, 255, 0.25) 7.41%, rgba(196, 130, 248, 0.49) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(217, 203, 255, 0.125) 7.41%, rgba(196, 130, 248, 0.245) 89.26%), #23122A'
    }
  },
  {
    title: 'avax',
    symbol: 'avax',
    contract: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    currency: 'AVAXIcon',
    chain: 'avax',
    address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.13) 7.41%, rgba(248, 137, 130, 0.35) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.07) 7.41%, rgba(248, 137, 130, 0.175) 89.26%), #2A1219'
    }
  },

  {
    title: 'usdt',
    subtitle: '.e',
    symbol: 'usdt.e',
    contract: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    currency: 'USDTIcon',
    chain: 'avax',
    address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(156, 237, 188, 0.11) 7.41%, rgba(156, 237, 188, 0.46) 89.26%), #FFFFFF;',
      dark: 'linear-gradient(148.3deg, rgba(156, 237, 188, 0.055) 7.41%, rgba(156, 237, 188, 0.23) 89.26%), #122A26'
    }
  },
  {
    title: 'wbtc',
    subtitle: '.e',
    symbol: 'wbtc.e',
    contract: '0x50b7545627a5162f82a992c33b87adc75187b218',
    currency: 'BTCIcon',
    chain: 'avax',
    address: '0x50b7545627a5162f82a992c33b87adc75187b218',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(255, 245, 221, 0.4) 7.41%, rgba(255, 238, 198, 0.6) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(255, 245, 221, 0.2) 7.41%, rgba(255, 238, 198, 0.3) 89.26%), #282A12'
    }
  },
  {
    title: 'aave',
    subtitle: '.e',
    symbol: 'aave.e',
    contract: '0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
    currency: 'AAVEIcon',
    chain: 'avax',
    address: '0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(156, 207, 224, 0.06) 7.41%, rgba(156, 207, 224, 0.41) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(156, 207, 224, 0.03) 7.41%, rgba(156, 207, 224, 0.205) 89.26%), #12292A'
    }
  },
  {
    title: 'qi',
    currency: 'BENQIIcon',
    symbol: 'qi',
    contract: '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    chain: 'avax',
    address: '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    color:
    {
      light: ' linear-gradient(148.3deg, rgba(221, 221, 221, 0.3) 7.41%, rgba(193, 193, 193, 0.3) 89.26%), #FFFFFF',
      dark: ' linear-gradient(148.3deg, rgba(221, 221, 221, 0.2) 7.41%, rgba(193, 193, 193, 0.2) 89.26%), #0C222B'
    }
  },


  {
    title: 'link',
    subtitle: '.e',
    symbol: 'link.e',
    contract: '0x5947BB275c521040051D82396192181b413227A3',
    currency: 'LINKIcon',
    chain: 'avax',
    address: '0x5947BB275c521040051D82396192181b413227A3',
    color:
    {
      light: 'linear-gradient(165.97deg, rgba(138, 176, 255, 0.17) 6.95%, rgba(134, 172, 255, 0.29) 90%), #FFFFFF',
      dark: 'linear-gradient(165.97deg, rgba(138, 176, 255, 0.085) 6.95%, rgba(134, 172, 255, 0.145) 90%), #121A2A'
    }
  },
  {
    title: 'xava',
    symbol: 'xava',
    contract: '0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4',
    currency: 'XAVAIcon',
    chain: 'avax',
    address: '0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.13) 7.41%, rgba(248, 137, 130, 0.35) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.065) 7.41%, rgba(248, 137, 130, 0.175) 89.26%), #23122A'
    }
  },

];

export const helps = [
  {
    label: "Getting Started",
    href: "https://hurricaneswap.medium.com/hurricaneswap-trading-tutorial-3290672d60b6",
  },
  {
    label: "How to Use the Hurricane Bridge (For Heco & BSC)",
    href: "https://hurricaneswap.medium.com/how-to-use-the-hurricane-bridge-for-heco-bsc-users-f0dbb723c1b",
  },
  {
    label: "Whitepaper",
    href: "https://hurricanesam.github.io/Docs/HurricaneSwap%20Whitepaper.pdf",
  },

];

export const messages = [
  {
    label: "The Winning List of Testnet Event",
    href: "https://hurricaneswap.medium.com/winners-of-the-whole-hurricaneswap-testnet-reward-program-dba7f5ec9a3a",

  },
  {
    label: "HurricaneSwap Public Sale",
    href: "https://hurricaneswap.medium.com/hurricaneswap-public-sale-announcement-tutorial-daec21b5d1d1",

  },
  {
    label: "HurricaneSwap is Integrating Chainlink Price Feeds & Proof of Reserve",
    href: "https://hurricaneswap.medium.com/hurricaneswap-is-integrating-chainlink-price-feeds-to-close-cross-chain-price-gaps-proof-of-85cc721641d",

  },

];

const CoinToShow = process.env.REACT_APP_ENV === 'MAIN' ? Maintokenlist : TestTokenList

export default CoinToShow;

