import { Trade,ChainId } from '@glhf-libs/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, CardBody, Flex, Text, ToastContainer } from '@hurricaneswap/uikit'
import { NavLink, useLocation } from 'react-router-dom'
import { JsonRpcProvider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import styled, { ThemeContext } from 'styled-components'
import axios from "axios"
import { WrappedTokenInfo } from 'state/lists/hooks'
import { useTokenContract } from 'hooks/useContract'
import { AutoColumn } from 'components/Column'
import ConfirmSwapModal from 'components/Bridge/ConfirmSwapModal'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { AutoRow,RowBetween } from 'components/Row'
import AdvancedSwapDetailsDropdown from 'components/swap/AdvancedSwapDetailsDropdown'
import { ArrowWrapper, BottomGrouping, IconDecoration, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import TranslatedText from 'components/TranslatedText'
import { useActiveWeb3React } from 'hooks'
import { useCurrencyWithAllChains } from 'hooks/Tokens'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import { Field } from 'state/swap/actions'
import { useDerivedStakeInfo, useSwapActionHandlers, useSwapState } from 'state/swap/hooks'
import { useExpertModeManager } from 'state/user/hooks'
import { TYPE } from 'components/Shared'
import { TranslateString } from 'utils/translateTextHelpers'
import PageHeader from 'components/PageHeader'
import ConnectWalletButton from 'components/ConnectWalletButton'
import '../../css/modal.css'
import { useTokenBalance } from 'data/Balance'
import useTheme from '../../hooks/useTheme'
import { Dots } from '../swap/styleds'
import useOnlyOnAvax from '../../hooks/useOnlyOnAvax'
import { useSwitchChain } from '../../hooks/Station'
import hurricane from '../../assets/imgs/hurricane.png'
import useMobile from '../../hooks/useMobile'


const Star = styled.div`
    &::before {
        content: '';
        position: absolute;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAOCAYAAAAbvf3sAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADASURBVHgBjZDBDcIwDEVtN+XCodmAIIHEsaMwQiUYhFHYBCSQuDJC2CAXDrTUxlU5OlJ8iWLn5ft/AKO6cPGQKbKazrk2B5mAiPglNk0xgEjhU73XxYBqeBYK1sSZzxEDgSRTfTJX14swwtiSYNB1VgKyR8AJuIrwSz09iao4DH3UlbyaZI8M84+iDUA95jsrKAT/xDJpH7b383FzOxV7AJbEQLEYQMSIwCZgxsrISc2nYgVNKw7fPkJpdbtHyM1+MbVFVwJHUAQAAAAASUVORK5CYII=') no-repeat;
        width: 16px;
        height: 16px;
        display:block;

`

const UlGrid = styled.ul`
  display: grid;
  margin-top:30px;
  grid-template-columns: repeat(2, 50%);
  justify-items: start;
  align-items: center;
  border-top: 1px solid ${({ theme }) => { return theme.colors.tokenInfoBorder }}; 
  li{
    border-left: 1px solid ${({ theme }) => { return theme.colors.tokenInfoBorder }};
    width: 100%;
    // height: 100%;
    padding: 10px 15px;
    display: inline-block;
    border-bottom: 1px solid ${({ theme }) => { return theme.colors.tokenInfoBorder }};
    &:nth-of-type(2n-1){
      padding-left: 0;
      padding-right: 0;
      border-left: none;
    }
  }
`

const CardContainer = styled.div<{ifMobile: boolean}>`
  max-width: 710px;
  width: 100%;
  padding: ${({ifMobile})=>(ifMobile ? '0 10px' : '0')};
  box-sizing: border-box;
  // height: 285px;
  overflow: hidden;
  position: relative;
  background: ${({theme})=>(theme.colors.swapContainer)};
  // border:${ ({ theme }) => (theme.isDark ? '1px solid #ffffff47' : 'none') }; 
  border-radius: 20px;
`
const Card = styled.div<{ifMobile?: boolean}>`
  width: 100%;
  height: 100%;
  // padding: 35px 5px 0 26px;
  padding: 5px;
  background-image: url(${({ifMobile})=>(!ifMobile ? hurricane : null)});
  background-size: ${({theme}) => theme.isDark ? 'contain':'cover'};
  background-position: ${({theme}) => theme.isDark ? 'right':'center'};
  background-repeat: no-repeat;
`
const Circle = styled.div`
  max-width: 332px;
  max-height: 332px;
  position: relative;
  ::after{
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid black;  

  }
`

const { main: Main } = TYPE


const Fixed = styled.section`
  position: fixed;
  top: var(--modalTop);
  right: var(--modalRight);
`

const ZIndex = styled.section`
  position: relative;
  z-index: 0;
`


const hctBarAddress = '0xe81D3f95C887ce9a89198312774d4274EeC7E43f'

const HCTAddr = process.env.REACT_APP_ENV === 'MAIN' ? '' : '0xeddeb2ff49830f3aa30fee2f7fabc5136845304a'
const xHCTAddr = process.env.REACT_APP_ENV === 'MAIN' ? '0x75B797a0ed87F77bB546F3A3556F18fC35a01140' : '0xe81D3f95C887ce9a89198312774d4274EeC7E43f'
const _chainId = process.env.REACT_APP_ENV === 'MAIN' ? ChainId.AVAX_MAINNET : ChainId.AVAX_TESTNET

const xHCT = new WrappedTokenInfo({
    "chainId": _chainId,
    "address": xHCTAddr,
    "decimals": 18,
    "name": "xHCT",
    "symbol": "xHCT",
    "logoURI": "https://i.loli.net/2021/10/14/lLkUNDhe436YM7t.png"
}, [])

const HCT = new WrappedTokenInfo({
    "chainId": _chainId,
    "address": HCTAddr,
    "decimals": 18,
    "name": "HCT",
    "symbol": "HCT",
    "logoURI": "https://i.loli.net/2021/08/29/yVGFeJkSvUaMRHP.png"
}, [])


const numberWithCommas = (x: any) => {
    return (typeof x === "string") ? x.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
  }
const StakeInfo = () => {
  const ifMobile = useMobile()
  const theme = useContext(ThemeContext)
  const titile = 'Stake HCT'
  const des1 = 'Currently, for every swap on HurricaneSwap, 0.1% fee is collected to repurchase HCT and distribute to users who staked HCT on Stake Page.  The repurchase & distribution will happen every 48h.'
  const des2 = 'When you stake HCT, you will receive the xHCT and could stake in Farm Page to receive HCT yield farming reward.'
  return (
    <CardContainer ifMobile={ifMobile}>
      <Card ifMobile={ifMobile}>
        <Flex>
          <Flex alignItems={ifMobile?'center':'flex-start'} flexDirection='column' pt='30px' pl='21px' pr='21px'  style={{boxSizing: 'content-box'}}>
            <Text mb='20px' fontSize='32px'>{titile}</Text>
            
                <Star/>
                {/* @ts-ignore */}
                <Text textAlign={ifMobile?'center':'left'} mb='10px' lineHeight='24px'  color={()=>theme.isDark ? '#e4e2e7':'rgba(77, 60, 85, 0.65);'} style={{'textIndent':'20px'}}>{des1}</Text>
    
                <Star/>
                {/* @ts-ignore */}
                <Text textAlign={ifMobile?'center':'left'} mb='10px' lineHeight='30px'  color={()=>theme.isDark ? '#e4e2e7':'rgba(77, 60, 85, 0.65);'}style={{'textIndent':'20px'}}>{des2}</Text>
      
          </Flex>
        </Flex>
      </Card>
    </CardContainer>

  )
}

// 数据要从外层接！！！！
const StakeData = () => {
    const theme = useContext(ThemeContext)
    const [fees, setFees] = useState(0)
    const stakeValue = useTokenBalance(HCT, [hctBarAddress])?.toSignificant() || 0
    const averageApr = 6.12
    const [apr, setApr] = useState(0)
    const [hct,setHct] = useState(0)
    const Fees = async () => {
      // const res = await axios.post("https://api.thegraph.com/subgraphs/name/peratx/hurricane-exchange-subgraph-fuji", {
      const res = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange", {
        // 之后换成每天的   
          query: `{
            pancakeDayDatas(first:1,orderBy:id,orderDirection:desc) {
              id
              dailyVolumeUSD
            }
          }`,
          variables: null
      });
      
      if (res?.data?.data?.pancakeDayDatas) {
          const result = res?.data?.data?.pancakeDayDatas[0]?.dailyVolumeUSD
          // console.log(result,'2222')
          setFees(result)  
      }
      };
      useEffect(() => {

        Fees()
      }, [])



        const hctPrice = async () => {
          // const res = await axios.post("https://api.thegraph.com/subgraphs/name/peratx/hurricane-exchange-subgraph-fuji", {
          const res = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange", {
            // 之后换成每天的   
              query: `{
                tokens(where:{
                  id:"0x45c13620b55c35a5f539d26e88247011eb10fdbd"
                }){
                  id
                  symbol
                  derivedUSD
                 
                }
              
              }`,
              variables: null
          });
          if (res?.data?.data?.tokens) {

              const result = res?.data?.data?.tokens[0]?.derivedUSD 
              // console.log(res?.data?.data?.pancakeFactories[0]['totalVolumeUSD'],'2222')
              setHct(result)  
          }
          };

          const getApr = async (thefees,HctPrice) => {
            // const res = await axios.post("https://api.thegraph.com/subgraphs/name/peratx/hurricane-exchange-subgraph-fuji", {
            const res = await axios.post("https://gateway.thegraph.com/api/[api-key]/subgraphs/id/0x4bb4c1b0745ef7b4642feeccd0740dec417ca0a0-2", {
              // 之后换成每天的   
                query: `{
                  bars(first: 5) {
                    id
                    totalSupply
                    ratio
                  }
                }`,
                variables: null
            });
          //  console.log(res,'1234')
            if (res?.data?.data?.pancakeFactories) {
                const totalSupply = res?.data?.data?.totalSupply[0]
                const ratio = res?.data?.data?.ratio[0]
                const result  = (365 * thefees * 0.05)/ totalSupply / (ratio * HctPrice)
                // console.log(res?.data?.data?.pancakeFactories[0]['totalVolumeUSD'],'2222')
                setApr(result *100)  
            }
            };


        useEffect(() => {
  
          Fees()
          hctPrice()
          getApr(fees,hct)
 
        }, [hct,fees])

    return (      
        <UlGrid theme={theme}>
        <li>
          <Text mb='4px' fontSize='12px' color={theme.colors.text}>Total Staked</Text>
          <Text style={{ wordBreak: 'break-all' }}
            fontSize='16px'>{` ${numberWithCommas(stakeValue).toLocaleString() }`} HCT</Text>
        </li>
        <li>
          <Text mb='4px' fontSize='12px' color={theme.colors.text}>Fees(24h)</Text>
          <Text style={{ wordBreak: 'break-all' }}
            fontSize='16px'>{`$ ${numberWithCommas(Number(fees*0.001).toFixed(2)).toLocaleString()}`}</Text>
        </li>
        <li>
          <Text mb='4px' fontSize='12px' color={theme.colors.text}>APR(24h)</Text>
          <Text style={{ wordBreak: 'break-all' }}
            fontSize='16px'>{`${ numberWithCommas(apr) || '--'} `} %</Text>
        </li>
        <li>
          <Text mb='4px' fontSize='12px' color={theme.colors.text}>Hct Price</Text>
          <Text style={{ wordBreak: 'break-all' }}
            fontSize='16px'>{`$ ${numberWithCommas(Number(hct).toFixed(3))}`}</Text>
        </li>
  
      </UlGrid>)

}



const StakeDec = ({ history }) => {
   // console.log('stake !!!')
    return (

            <Flex flexDirection='column'>
                <StakeInfo/>
                <StakeData/>
            </Flex>
    )


} 

export default StakeDec