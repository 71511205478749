import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Pair } from '@glhf-libs/sdk'
import { Button, CardBody, Text, Flex } from '@hurricaneswap/uikit'
import { StationFullPositionCard } from 'components/PositionCard'
import SelectNetwork from 'components/SelectNetwork'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import { TYPE } from 'components/Shared'
import { TransparentCard as LightCard } from 'components/Card'
import { RowBetween } from 'components/Row'
import { AutoColumn } from 'components/Column'
import { FiArrowLeft, FiClock } from "react-icons/fi";

import { useActiveWeb3React } from 'hooks'
import { useStationPairs } from 'data/Reserves'
import { toV2LiquidityToken, useStationTrackedTokenPairs } from 'state/user/hooks'
import { Dots } from 'components/swap/styleds'
import TranslatedText from 'components/TranslatedText'
import AppBody from '../AppBody'
import ChooseChain from '../../components/ChooseChain'
import useMobile from '../../hooks/useMobile'

const { body: Body } = TYPE

const MinHeightContainer = styled.div`
  min-height: 376px;
`

const Title = styled(Text) <{ ifMobile: boolean }>`
  font-family: GTTextBold;
  font-size: ${({ ifMobile }) => ifMobile ? '28px' : '2.5em'};
  line-height: 46px;
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.text};
`
export function StationPoolTest() {
  const history = useHistory()
  const theme = useContext(ThemeContext)
  const { account, chainId } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useStationTrackedTokenPairs()

  const filteredPairs = useMemo(() => trackedTokenPairs.filter(i => i[0]?.symbol?.includes('USDT') || (i[1]?.symbol?.includes('USDT'))), [trackedTokenPairs])

  const tokenPairsWithLiquidityTokens = useMemo(
    () => filteredPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [filteredPairs]
  )

  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])

  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        // v2PairsBalances[liquidityToken.address]?.greaterThan('0')
        liquidityToken.address
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = useStationPairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))

  const v2IsLoading = false
  // fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))
  const filterAllowPairsWithLiquidity = useMemo(() => (allV2PairsWithLiquidity.filter(i => i)), [allV2PairsWithLiquidity])

  const [isOpen, setIsOpen] = useState(false)
  // const [onPresent1] = useModal (<ChooseChain isOpen={isOpen} portalId={portalId} history={ history }/>, false)
  const stationStatus = true

  const openModal = () => {
    setIsOpen(true)
    // onPresent1()
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const onDismiss = () => {
    return closeModal()
  }

  useEffect(() => {
    // todo 添加from验证
    if( chainId !== 56 && chainId !== 97 ){
      openModal()
    }
  }, [chainId])

  return (
    <>
      <AppBody>
        <MinHeightContainer>
          {/* <SelectNetwork onDismiss={onDismiss} noNeedClose isOpen={isOpen} portalId="station"/> */}

          <div id="station" >
            <ChooseChain noNeedClose onDismiss={onDismiss} isOpen={isOpen} portalId="station" />
          </div>

          <Flex p='27px 29px' justifyContent='space-between' alignItems='center'>
            <FiArrowLeft cursor='pointer' onClick={openModal} size='24px' />
            <Flex width='100%' justifyContent='center' flex={1}><Text textAlign='center' fontSize='20px'>Select a Pair</Text></Flex>
            {/* 历史交易记录  */}
            {/* <FiClock size='24px' /> */}
          </Flex>

          {/* <PageHeader title="Station-LP" description="Add liquidity to receive HURRICANE-LP tokens">
            <Button id="join-pool-button" as='button' onClick={openModal} disabled={!stationStatus} width='100%'>
              <TranslatedText
                translationId={100}>Select Chain</TranslatedText>
            </Button>
          </PageHeader> */}

          <AutoColumn gap="lg" justify="center">
            <CardBody style={{ width: '100%' }} p='24px 24px 48px'>
              <AutoColumn gap="12px" style={{ width: '100%' }}>
                <RowBetween padding="0 8px">
                  <Text color={theme.colors.text}>
                    <TranslatedText translationId={102}>Your Station Liquidity</TranslatedText>
                  </Text>
                </RowBetween>

                {!account ? (
                  <LightCard padding="40px">
                    <Body color={theme.colors.textDisabled} textAlign="center">
                      Connect to a wallet to view your liquidity.
                    </Body>
                  </LightCard>
                ) : v2IsLoading ? (
                  <LightCard padding="40px">
                    <Body color={theme.colors.textDisabled} textAlign="center">
                      <Dots>Loading</Dots>
                    </Body>
                  </LightCard>
                ) : filterAllowPairsWithLiquidity?.length > 0 ? (
                  <>
                    {filterAllowPairsWithLiquidity.map((v2Pair) => (
                      <StationFullPositionCard loading={fetchingV2PairBalances} key={v2Pair.liquidityToken.address} pair={v2Pair} />
                    ))}
                  </>
                ) : (
                  <LightCard padding="40px">
                    <Body color={theme.colors.textDisabled} textAlign="center">
                      <TranslatedText translationId={104}>No liquidity found.</TranslatedText>
                    </Body>
                  </LightCard>
                )}

                {
                  stationStatus ? (
                    null
                  ) : (
                    <div>
                      <Text fontSize="14px" style={{ padding: '.5rem 0 .5rem 0' }}>
                        Please wait for station opened
                      </Text>
                    </div>)
                }

              </AutoColumn>
            </CardBody>
          </AutoColumn>

        </MinHeightContainer>
      </AppBody>
    </>
  );
}


const StationPoolMain = () => {
  const ifMobile = useMobile()
  return (
    <Flex flexDirection='column' width={ifMobile ? '370px' : '700px'} mt='150px' alignItems='center' ml={ifMobile ? '0px' : '-210px'}>
      <Title ifMobile={ifMobile}>Station in testnet will open in Oct 12th</Title>
      {/* @ts-ignore */}
      <Button width={ifMobile ? '370px' : '416px'} style={{ marginTop: '56px' }} variant="tertiary"><a href='https://test.hurricaneswap.com' target='_blank' rel="noreferrer">Go to testnet</a></Button>
      <Flex flexDirection='row' width={ifMobile ? '370px' : '416px'} justifyContent='space-around' mt='32px'>
        <Button variant="secondary" width={ifMobile ? '180px' : '200px'} height='48px'><a href='https://hurricaneswap.medium.com/hurricaneswap-trading-tutorial-3290672d60b6' target='_blank' rel="noreferrer">Tutorial</a></Button>
        <Button variant="secondary" width={ifMobile ? '180px' : '200px'} height='48px'><a href='https://github.com/HurricaneSam/AuditReport/blob/main/PeckShield-Audit-Report-HurricanSwapV2-v1.0rc.pdf' target='_blank' rel="noreferrer">Audit report</a></Button>
      </Flex>
    </Flex>
  )
}
// env 更改配置测试网和主网
const StationPool = process.env.REACT_APP_ENV === 'MAIN' ? StationPoolMain : StationPoolTest
export default StationPool