/* eslint-disable */
const display = process.env.REACT_APP_ENV === 'MAIN' ?  false :true

export const config = [
  {
    label: "Home",
    href: "/"
  },
  {
    label: "Swap",
    href: "/swap",
  },
  {
    label: "Stake",
    href: "/stake",
  },
  {
    label: "Farm",
    href: '/farm' , 
    disabled: display
  },

  {
    label: "Transfer",
    children: [
      {
        label: "Station",
        href: "/stationpool",
      },
      {
        label: "Bridge",
        href: "/bridge",
        disabled: display
      },
    ]
  },
  // {
  //   label: "Info",
  //   disabled: true
  // },
];

export default config
