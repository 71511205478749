import { currencyEquals, Trade, JSBI } from '@glhf-libs/sdk'
import React, { useCallback, useMemo } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { Flex, Text, Button } from '@hurricaneswap/uikit'
import { getNetwork } from 'components/Menu'
import SwapModalHeader from 'components/swap/SwapModalHeader'
import TranslatedText from 'components/TranslatedText'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'
import useMobile from 'hooks/useMobile'
import { Field } from 'state/swap/actions'
import TransactionConfirmationModal, {
    TransactionErrorContent
} from '../TransactionConfirmationModal'
import { useActiveWeb3React } from '../../hooks'
import { useBaseCoin } from '../../hooks/useBaseCoin'
import { Wrapper, Section, ContentHeader, BottomSection } from './help'

/**
 * Returns true if the trade requires a confirmation of details before we can submit it
 * @param tradeA trade A
 * @param tradeB trade B
 */
function tradeMeaningfullyDiffers(tradeA: Trade, tradeB: Trade): boolean {
    return (
        tradeA.tradeType !== tradeB.tradeType ||
        !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
        !tradeA.inputAmount.equalTo(tradeB.inputAmount) ||
        !currencyEquals(tradeA.outputAmount.currency, tradeB.outputAmount.currency) ||
        !tradeA.outputAmount.equalTo(tradeB.outputAmount)
    )
}

const RightArrow = () => {
    const { isDark } = useTheme()
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 20H27" stroke={isDark ? '#fff' : '#4D3C55'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 13L27 20L20 27" stroke={isDark ? '#fff' : '#4D3C55' } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const Divider = styled.div`
margin: 16px 0;
width: 100%;
height: 1px;
background: ${({ theme }) => (theme.colors.tokenInfoBorder)};
`
export const getSubStr = (str) => {
    if (str) {
        const subStr1 = str.substr(0, 12);
        const subStr2 = str.substr(str.length - 10, 10);
        return `${subStr1}...${subStr2}`
    }
    return str
}

const NetworkTransferDir = () => {
    const { isDark } = useTheme()
    const { chainId } = useActiveWeb3React()
    return (
        <Flex>
            <Flex flex={1} flexDirection='column'>
                <Text mb='12px' color={isDark ? 'rgba(255, 255, 255, 0.65)' : '#8A7F90'}>From</Text>
                <Flex height='40px' alignItems='center' justifyContent='center'>{getNetwork(chainId)}</Flex>
            </Flex>
            <Flex alignSelf='flex-end'>
                <RightArrow />
            </Flex>
            <Flex flex={1} flexDirection='column'>
                <Text mb='12px' color={isDark ? 'rgba(255, 255, 255, 0.65)' : '#8A7F90'}>to</Text>
                <Flex height='40px' alignItems='center' justifyContent='center'>AVAX</Flex>
            </Flex>
        </Flex>
    )
}

export default function ConfirmSwapModal({
    account,
    parsedAmounts,
    trade,
    originalTrade,
    onAcceptChanges,
    allowedSlippage,
    onConfirm,
    onDismiss,
    recipient,
    swapErrorMessage,
    isOpen,
    attemptingTxn,
    txHash
}: {
    account: string | undefined | null
    parsedAmounts: any
    isOpen: boolean
    trade: Trade | undefined
    originalTrade: Trade | undefined
    attemptingTxn: boolean
    txHash: string | undefined
    recipient: string | null
    allowedSlippage: number
    onAcceptChanges: () => void
    onConfirm: () => void
    swapErrorMessage: string | undefined
    onDismiss: () => void
}) {

    const amountIn = useMemo(() => {
        if (parsedAmounts && parsedAmounts[Field.INPUT]) {
            return parsedAmounts[Field.INPUT].toSignificant(6)
        }
        return 0
    }, [parsedAmounts])

    const receivedHCT = useMemo(() => {
        if (parsedAmounts && parsedAmounts[Field.INPUT]) {
            const _ = JSBI.subtract(parsedAmounts[Field.INPUT].raw, JSBI.BigInt(10 * 10 ** 18))
            const __ = JSBI.toNumber(_) / (10 ** 18)
            return __
        }
        return 0
    }, [parsedAmounts])

    const showAcceptChanges = useMemo(
        () => Boolean(trade && originalTrade && tradeMeaningfullyDiffers(trade, originalTrade)),
        [originalTrade, trade]
    )

    const { chainId } = useActiveWeb3React()
    const { isDark } = useTheme()
    const ifMobile = useMobile()

    const inputSymbol = useBaseCoin(trade?.inputAmount?.currency, chainId)
    const outPutSymbol = useBaseCoin(trade?.outputAmount?.currency, chainId)

    const modalHeader = useCallback(() => {
        return trade ? (
            <SwapModalHeader
                trade={trade}
                allowedSlippage={allowedSlippage}
                recipient={recipient}
                showAcceptChanges={showAcceptChanges}
                onAcceptChanges={onAcceptChanges}
            />
        ) : null
    }, [allowedSlippage, onAcceptChanges, recipient, showAcceptChanges, trade])

    // text to show while loading
    const pendingText = `Swapping ${trade?.inputAmount?.toSignificant(6)} ${inputSymbol
        } for ${trade?.outputAmount?.toSignificant(6)} ${outPutSymbol}`

    const confirmationContent = useCallback(
        () =>
            swapErrorMessage ? (
                <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} />
            ) : (
                <Wrapper style={{ display: 'flex', flexDirection: 'column' }}>
                    <Section>
                        <ContentHeader onDismiss={onDismiss}>Confirm</ContentHeader>
                        {modalHeader()}
                    </Section>

                    <Section style={{ fontSize: '16px', width: '100%' }}>
                        <NetworkTransferDir />
                        <Flex mt='10px' alignItems='center' justifyContent='space-between'>
                            <Text color='#8A7F90'>Bridge Fee:</Text>
                            <Text color='#8A7F90' textAlign='right'>10 HCT</Text>
                        </Flex>




                        <Divider />
                        {/* amount */}
                        <Flex flexDirection='column'>
                            <Text >You will receive</Text>
                            <Text fontSize='32px' color={isDark ? 'rgba(255, 255, 255, 0.85)' : '#67586E'}>{receivedHCT} HCT</Text>
                        </Flex>
                        {/* address */}
                        <Flex flexDirection='column' mt='20px'>
                            <Text >Receive address</Text>
                            <Text color={isDark ? 'rgba(255, 255, 255, 0.12)' : '#CFCAD3'}>{ifMobile ? getSubStr(account) : account}</Text>
                        </Flex>
                        {/* <Divider /> */}
                        {/* fee */}
                        <Button mt='16px'
                            onClick={onConfirm}
                            disabled={amountIn <= 10}
                            width='100%'
                        >
                            <TranslatedText translationId={100}>
                                Swap
                            </TranslatedText>
                        </Button>

                    </Section>

                    {/* <BottomSection style={{ flex: 1 }} gap="12px">{modalBottom()}</BottomSection> */}
                </Wrapper>
            ),
        [receivedHCT, onConfirm, onDismiss, modalHeader, swapErrorMessage, amountIn, account, isDark, ifMobile]
    )

    return (
        <TransactionConfirmationModal
            isOpen={isOpen}
            onDismiss={onDismiss}
            attemptingTxn={false}
            hash={undefined}
            content={confirmationContent}
            pendingText={pendingText}
        />
    )
}
