import { currencyEquals, Trade } from '@glhf-libs/sdk'
import React, { useCallback, useMemo } from 'react'
import { Flex, Text, Button, Progress, LinkExternal } from '@hurricaneswap/uikit'
import useMobile from 'hooks/useMobile'
import { getNetwork } from 'components/Menu'
import SwapModalHeader from 'components/swap/SwapModalHeader'
import TranslatedText from 'components/TranslatedText'
import useTheme from 'hooks/useTheme'
import styled, { css, keyframes } from 'styled-components'
import { Field } from 'state/swap/actions'
import { getBscScanLink } from 'utils'
import TransactionConfirmationModal, {
    TransactionErrorContent
} from '../TransactionConfirmationModal'
import { useActiveWeb3React } from '../../hooks'
import { useBaseCoin } from '../../hooks/useBaseCoin'
import { Wrapper, Section, ContentHeader, BottomSection } from './help'

/**
 * Returns true if the trade requires a confirmation of details before we can submit it
 * @param tradeA trade A
 * @param tradeB trade B
 */
function tradeMeaningfullyDiffers(tradeA: Trade, tradeB: Trade): boolean {
    return (
        tradeA.tradeType !== tradeB.tradeType ||
        !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
        !tradeA.inputAmount.equalTo(tradeB.inputAmount) ||
        !currencyEquals(tradeA.outputAmount.currency, tradeB.outputAmount.currency) ||
        !tradeA.outputAmount.equalTo(tradeB.outputAmount)
    )
}

const StyledAbsoluteWrapper = styled.div<{ left: any, top: any }>`
    position: absolute;
    transform: translateY(-50%);
    ${({ top }) => (top && css`top: ${top}`)};
    ${({ left }) => (left && css`left: ${left}`)};
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const AnimationContainer = styled.div`
  animation: 7s ${rotate} linear infinite;
`

const SuccessIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3334 9.23336V10C18.3324 11.797 17.7505 13.5456 16.6745 14.9849C15.5986 16.4242 14.0862 17.4771 12.3629 17.9866C10.6396 18.4961 8.7978 18.4349 7.11214 17.8122C5.42648 17.1894 3.98729 16.0384 3.00922 14.5309C2.03114 13.0234 1.56657 11.2401 1.68481 9.44696C1.80305 7.65383 2.49775 5.94697 3.66531 4.58092C4.83288 3.21488 6.41074 2.26285 8.16357 1.86682C9.91641 1.47079 11.7503 1.65198 13.3918 2.38336" stroke="#06C270" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.3333 3.33331L10 11.675L7.5 9.17498" stroke="#06C270" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const LoadingIcon = () => {
    return (
        <AnimationContainer>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 15V18.3333" stroke="#8A7F90" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.5333 13.5333L15.8917 15.8917" stroke="#8A7F90" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.10834 15.8917L6.46667 13.5333" stroke="#8A7F90" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15 10H18.3333" stroke="#8A7F90" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.66666 10H4.99999" stroke="#8A7F90" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.5333 6.46667L15.8917 4.10834" stroke="#8A7F90" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.10834 4.10834L6.46667 6.46667" stroke="#8A7F90" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10 1.66669V5.00002" stroke="#8A7F90" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </AnimationContainer>
    )
}

const StyledProgress = styled.div<{ ifMobile?: boolean }>`
    >div{
        z-index: -1;
        width: calc(100% - 88px);
        height: 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - ${(({ ifMobile }) => (ifMobile ? '30px' : '20px'))}));
    }
    >div>div{
        height: 8px;
        background-color: #06C270;
    }
`

const Divider = styled.div`
margin: 16px 0;
width: 100%;
height: 1px;
background: ${({ theme }) => (theme.colors.tokenInfoBorder)};
`
const config = {
    1: {
        src: "/images/coins/ETH.svg",
    },
    56: {
        src: "/images/coins/BSC.svg"
    },
    97: {
        src: "/images/coins/BSC.svg"
    },
    256: {
        src: "/images/coins/HECO.svg"
    },
    128: {
        src: "/images/coins/HECO.svg"
    },
    43113: {
        src: "/images/coins/AVAX.svg"
    },
    43114: {
        src: "/images/coins/AVAX.svg"
    }
}

const TokenContainer = styled.div`
    padding: 8px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #fff;
`

const StyledBnbLogo = styled.img<{ size: string, status?: boolean }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
  transition: all linear .3s;
  ${({ status }) => (!status && 'filter: grayscale(1)')};

`

export default function ConfirmSwapModal({
    transactionHash,
    bridgeStatus = 0,
    account,
    parsedAmounts,
    trade,
    originalTrade,
    onAcceptChanges,
    allowedSlippage,
    onConfirm,
    onDismiss,
    recipient,
    swapErrorMessage,
    isOpen,
    attemptingTxn,
    txHash
}: {
    transactionHash?: any
    bridgeStatus: any
    account: string | undefined | null
    parsedAmounts: any
    isOpen: boolean
    trade: Trade | undefined
    originalTrade: Trade | undefined
    attemptingTxn: boolean
    txHash: string | undefined
    recipient: string | null
    allowedSlippage: number
    onAcceptChanges: () => void
    onConfirm: () => void
    swapErrorMessage: string | undefined
    onDismiss: () => void
}) {

    const amountIn = useMemo(() => {
        if (parsedAmounts && parsedAmounts[Field.INPUT]) {
            return parsedAmounts[Field.INPUT].toSignificant(6)
        }
        return 0
    }, [parsedAmounts])

    const showAcceptChanges = useMemo(
        () => Boolean(trade && originalTrade && tradeMeaningfullyDiffers(trade, originalTrade)),
        [originalTrade, trade]
    )

    const { chainId } = useActiveWeb3React()
    const ifMobile = useMobile()

    const inputSymbol = useBaseCoin(trade?.inputAmount?.currency, chainId)
    const outPutSymbol = useBaseCoin(trade?.outputAmount?.currency, chainId)

    const modalHeader = useCallback(() => {
        return trade ? (
            <SwapModalHeader
                trade={trade}
                allowedSlippage={allowedSlippage}
                recipient={recipient}
                showAcceptChanges={showAcceptChanges}
                onAcceptChanges={onAcceptChanges}
            />
        ) : null
    }, [allowedSlippage, onAcceptChanges, recipient, showAcceptChanges, trade])

    // text to show while loading
    const pendingText = `Swapping ${trade?.inputAmount?.toSignificant(6)} ${inputSymbol
        } for ${trade?.outputAmount?.toSignificant(6)} ${outPutSymbol}`

    const confirmationContent = useCallback(
        () =>
            swapErrorMessage ? (
                <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} />
            ) : (
                <Wrapper style={{ display: 'flex', flexDirection: 'column' }}>
                    <Section>
                        <ContentHeader onDismiss={onDismiss}>Transferring</ContentHeader>
                        {modalHeader()}
                    </Section>

                    <Section style={{ fontSize: '16px', width: '100%' }}>
                        <Flex position='relative'>
                            <Flex width='100%'>
                                <Flex flex={1} flexDirection='column' alignItems='flex-start'>
                                    <TokenContainer>
                                        <StyledBnbLogo src={chainId ? config[chainId].src : config[43114].src} size='100%' status={bridgeStatus >= 0} />
                                    </TokenContainer>
                                    <Text mt='12px' fontSize='18px' textAlign='center'>{getNetwork(chainId)}</Text>
                                </Flex>
                                <Flex flex={1} flexDirection='column' alignItems='center'>
                                    <TokenContainer>
                                        <StyledBnbLogo src='/images/coins/hct.jpg' size='100%' status={bridgeStatus >= 50} />
                                    </TokenContainer>
                                    <Text mt='12px' fontSize='18px' color={bridgeStatus < 50 ? '#ACA4B1' : 'text'} textAlign='center'>Hurricane bridge</Text>
                                </Flex>
                                <Flex flex={1} flexDirection='column' alignItems='flex-end'>
                                    <TokenContainer>
                                        <StyledBnbLogo src={config[43114].src} size='100%' status={bridgeStatus >= 100} />
                                    </TokenContainer>
                                    <Text mt='12px' fontSize='18px' color={bridgeStatus < 100 ? '#ACA4B1' : 'text'} textAlign='center'>AVAX</Text>
                                </Flex>
                            </Flex>
                            <StyledProgress ifMobile={ifMobile}>
                                <Progress variant='round' primaryStep={bridgeStatus} />
                            </StyledProgress>
                            <StyledAbsoluteWrapper left='25%' top='50%' >
                                {
                                    bridgeStatus < 50 ? <LoadingIcon /> : <SuccessIcon />
                                }
                            </StyledAbsoluteWrapper>
                            <StyledAbsoluteWrapper left='70%' top='50%' >
                                {
                                    bridgeStatus >= 100 ? <SuccessIcon /> : <LoadingIcon />
                                }
                            </StyledAbsoluteWrapper>

                        </Flex>

                        <Divider />
                        {/* {bridgeStatus} */}

                        {/* amount */}
                        <Flex flexDirection='column' mt='20px' alignItems='center'>
                            <Text fontSize='20px'>{bridgeStatus >= 100 ? 'Tranfer seccessfully' : `Transfering ${amountIn - 10} HCT`} </Text>
                            {
                                bridgeStatus >= 100
                                    ? (<LinkExternal href={getBscScanLink(43114, transactionHash, 'transaction')}>View on Explorer</LinkExternal>)
                                    : (<Text mt='10px'>Confirm this transation in your wallet</Text>)
                            }
                        </Flex>
                    </Section>

                    {/* <BottomSection style={{ flex: 1 }} gap="12px">{modalBottom()}</BottomSection> */}
                </Wrapper>
            ),
        [transactionHash, chainId, onDismiss, modalHeader, swapErrorMessage, amountIn, bridgeStatus, ifMobile]
    )

    return (
        <TransactionConfirmationModal
            isOpen={isOpen}
            onDismiss={onDismiss}
            attemptingTxn={false}
            hash={undefined}
            content={confirmationContent}
            pendingText={pendingText}
        />
    )
}
