import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Box, Button, Flex, Text as UIKitText, Text, ToastContainer, useModal } from '@hurricaneswap/uikit'
import {
  HashRouter,
  Link as HistoryLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Credentials, StringTranslations } from '@crowdin/crowdin-api-client'
import { useActiveWeb3React } from 'hooks'
import useAuth from 'hooks/useAuth'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './AddLiquidity/redirects'
import { RedirectStationDuplicateTokenIds, RedirectStationOldAddLiquidityPathStructure } from './StationAddLiquidity/redirects'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import { RedirectStationOldRemoveLiquidityPathStructure } from './StationRemoveLiquidity/redirects'
import Home from './Home'
import AddLiquidity from './AddLiquidity'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Swap from './Swap'
import Test from './Test'
import StationPool from './StationPool'
import StationAddLiquidity from './StationAddLiquidity'
import StationPoolFinder from './StationPoolFinder'
import StationRemoveLiquidity from './StationRemoveLiquidity'
import Bridge from './Bridge'
import Stake from './Stake'
import { RedirectPathToSwapOnly, RedirectSwapDuplicateTokenIds } from './Swap/redirects'
import { EN, allLanguages } from '../constants/localisation/languageCodes'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'

import Menu from '../components/Menu'
import TokenInfo from '../components/TokenInfo'
import useStationLastUpdate from '../hooks/useStationLastUpdate'
import { AutoColumn } from '../components/Column'
import useStationStatus from '../hooks/useStationStatus'
import StationTokenInfo from '../components/StationTokenInfo'
import StakeDec from '../components/StakeInfo'
import useMobile from '../hooks/useMobile'
import '../style/index.css'

const ResponseiveFlex = styled.div<{ justifyContent: string }>`
  display: flex;
  justify-content: ${({justifyContent})=> justifyContent};  
  @media (max-width: 1200px) {
    flex-direction: column!important;
    flex-flow: wrap-reverse!important;
  }
`
const ResponsiveBox = styled.div`
  @media (max-width: 1200px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const ResponsiveSection = styled.section`
  width: 100%;
  max-width: 464px;
  margin-bottom: 20px;
  position: relative;
 
`
const ResponsiveLeftContainer = styled.section`
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const ResponsiveLeftContainerForStation = styled.section`
  max-width: 710px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const Divider = styled.div`
  display: inline-block;
  width: 177px;
  height: 100%;
  @media (max-width: 1200px) {
    display: none;
  }
`

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`
const WrapperPadding = styled.div`
  width: 100%;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
`

const BackgroundCover = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all linear .2s;
    background: ${({theme})=>(process.env.REACT_APP_ENV === 'MAIN' ? theme.colors.gradients.backgroundCover : theme.isDark ? theme.colors.gradients.backgroundCover:'repeating-linear-gradient(58.14deg, #d3e7ff 0%, #dfd3f9 43%, #d3e7ff 100%)')}
    
`

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 80px 16px;
    padding-bottom: 0;
    align-items: center;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
    justify-content: flex-start;
    
    ${({ theme }) => theme.mediaQueries.xs} {
      background-size: auto;
    }
    
    ${({ theme }) => theme.mediaQueries.lg} {
      min-height: 90vh;
    }
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const Fixed = styled.section`
  position: fixed;
  top: 100px;
  right: 100px;
`

const TradingView = ()=>{
  const ifMobile = useMobile(1201)
  const {pathname} = useLocation()
  if(pathname.includes('home') || pathname==='/' || pathname.includes('bridge')){
    return null
  }
  if(pathname.includes('home') || pathname==='/' || pathname.includes('stake')){
    return (
      <Flex mr={ifMobile ? '0' : '30px'} mt={ifMobile ? '50px' : '0'}  width='710px'>
      <ResponsiveLeftContainer >
        <Box >
          {/* @ts-ignore */}
          <StakeDec/>
        </Box>
      </ResponsiveLeftContainer>
    </Flex>
    )
  }

  if(pathname.includes('station')){
    return(
      <Flex mr={ifMobile ? '0' : '30px'}  mt={ifMobile ? '50px' : '0'} width='100%'>
        <ResponsiveLeftContainerForStation >
          <Box >
            <StationTokenInfo/>
          </Box>
        </ResponsiveLeftContainerForStation>

      </Flex>
    )
  }

  return (
    <Flex mr={ifMobile ? '0' : '30px'} mt={ifMobile ? '50px' : '0'}  width='710px'>
      <ResponsiveLeftContainer >
        <Box >
          <TokenInfo/>
        </Box>
      </ResponsiveLeftContainer>
    </Flex>
  )
}

export default function App() {
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])
  const apiKey = `${  process.env.REACT_APP_CROWDIN_APIKEY}`
  const projectId = parseInt(`${process.env.REACT_APP_CROWDIN_PROJECTID}`)
  const fileId = 6

  const credentials: Credentials = {
    token: apiKey,
  }

  const stringTranslationsApi = new StringTranslations(credentials)

  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
  }

  useEffect(() => {
    const storedLangCode = localStorage.getItem('pancakeSwapLanguage')
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
    } else {
      setSelectedLanguage(EN)
    }
  }, [])

  const fetchTranslationsForSelectedLanguage = async () => {
    stringTranslationsApi
      .listLanguageTranslations(projectId, selectedLanguage.code, undefined, fileId, 200)
      .then((translationApiResponse) => {
        if (translationApiResponse.data.length < 1) {
          setTranslations(['error'])
        } else {
          setTranslations(translationApiResponse.data)
        }
      })
      .then(() => setTranslatedLanguage(selectedLanguage))
      .catch((error) => {
        setTranslations(['error'])
        console.error(error)
      })
  }


  // 开启轮询
  // const {stationStatus, error: contractError} = useStationStatus()

  const {account} = useActiveWeb3React()
  const {login} = useAuth()
  // 自动登录
  useEffect(()=>{
    if(!account){
      login()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
      <Suspense fallback={null}>
        <HashRouter>
          <AppWrapper>
            <BackgroundCover/>

            <LanguageContext.Provider
              value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}
            >
              <TranslationsContext.Provider value={{ translations, setTranslations }}>
                <Menu/>
                <BodyWrapper>
                  <Popups />
                  <Web3ReactManager>
                    <>
                    <Switch>
                      <WrapperPadding>
                        <ResponseiveFlex justifyContent="space-around">
                          <TradingView/>

                          <Route exact strict path="/" component={Home} />
                          <Route exact strict path="/home" component={Home} />
                          <ResponsiveSection id="dex">

                            {/* <ToastContainer toasts={toasts} onRemove={onRemove}/> */}
                              <Route exact strict path="/bridge" component={Bridge} />
                              <Route exact strict path="/stake" component={Stake} />
                              <Route exact strict path="/unstake" component={Stake} />
                              <Route exact strict path="/swap" component={Swap} />
                              <Route exact strict path="/find" component={PoolFinder} />
                              <Route exact strict path="/stationfind" component={StationPoolFinder} />
                              <Route exact strict path="/pool" component={Pool} />
                              <Route exact strict path="/stationPool" component={StationPool} />
                              <Route exact strict path="/test" component={Test} />
                              <Route exact strict path="/add" component={AddLiquidity} />
                              <Route exact strict path="/stationadd" component={StationAddLiquidity} />
                              <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                              <Route exact strict path="/stationremove/:currencyIdA/:currencyIdB" component={StationRemoveLiquidity} />

                              {/* Redirection: These old routes are still used in the code base */}
                              <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                              <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                              <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />

                              <Route exact path="/stationadd/:chainId/:currencyIdA" component={RedirectStationOldAddLiquidityPathStructure} />
                              <Route exact path="/stationadd/:chainId/:currencyIdA/:currencyIdB" component={RedirectStationDuplicateTokenIds} />
                              <Route exact strict path="/stationremove/:tokens" component={RedirectStationOldRemoveLiquidityPathStructure} />

                              <Route exact path="/swap/:chainId/:currencyIdA" component={RedirectSwapDuplicateTokenIds} />
                              <Route exact path="/swap/:chainId/:currencyIdA/:currencyIdB" component={RedirectSwapDuplicateTokenIds} />
                              {/* <Route component={RedirectPathToSwapOnly} /> */}
                              {/* <Redirect to="/home"/> */}
                          </ResponsiveSection>
                        </ResponseiveFlex>
                      </WrapperPadding>

                    </Switch>

                    </>
                  </Web3ReactManager>
                  <Marginer />
                </BodyWrapper>
                {/* <VersionBar /> */}
              </TranslationsContext.Provider>
            </LanguageContext.Provider>
          </AppWrapper>
        </HashRouter>
      </Suspense>
  )
}
