import { Trade } from '@glhf-libs/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, CardBody, Flex, Text, ToastContainer } from '@hurricaneswap/uikit'
import styled, { ThemeContext } from 'styled-components'
import { useTokenContract } from 'hooks/useContract'
import { FiArrowLeft, FiClock } from "react-icons/fi"
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { AutoColumn } from 'components/Column'
import ConfirmSwapModal from 'components/Bridge/ConfirmSwapModal'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { AutoRow } from 'components/Row'
import { Separator } from 'components/SearchModal/styleds'
import AdvancedSwapDetailsDropdown from 'components/swap/AdvancedSwapDetailsDropdown'
import { ArrowWrapper, BottomGrouping, IconDecoration, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import TranslatedText from 'components/TranslatedText'
import ChooseChain from 'components/BridgeChooseChain'
import ProcessModal from 'components/Bridge/ProcessModal'
import { useActiveWeb3React } from 'hooks'
import { useCurrencyWithAllChains } from 'hooks/Tokens'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import { Field } from 'state/swap/actions'
import { useDerivedBridgeInfo, useSwapActionHandlers, useSwapState } from 'state/swap/hooks'
import { useExpertModeManager } from 'state/user/hooks'
import { TYPE } from 'components/Shared'
import { TranslateString } from 'utils/translateTextHelpers'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from '../AppBody'
import '../../css/modal.css'
import { Dots } from '../../components/swap/styleds'

const ws = 'wss://api.avax.network/ext/bc/C/ws'

// todo 上主网需要替换minterAddress、topics、token_address
const blackHoleAddress = '0x0000000000000000000000000000000000000001'

const minterAddress = '0x2965d2E2750A0f01B4964f9f77A5e2cdAa31984B'

const tokenBridgeList = {
    hct: {
        56: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd',
        97: '0x198a50252f0aadbdc846adb988174b642c6665ae',
        128: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd',
        256: '0x504cfc9aa88a2cccbc5a2f5b0d201166ba12c9b4',
        43113: '0xFaEC2287dB34816b911c610beA28a0ab7324E5e5',
        43114: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd'
    }
}

const defaultChainConfig = [
    // {
    //     chainId: 97,
    //     disabled: false,
    //     src: "/images/coins/BSC.svg",
    //     chainName: 'Binance Smart Chain Test'
    // },
    // {
    //     chainId: 256,
    //     disabled: false,
    //     src: "/images/coins/HECO.svg",
    //     chainName: 'Heco Test'
    // },
    {
        chainId: 56,
        disabled: false,
        src: "/images/coins/BSC.svg",
        chainName: 'Binance Smart Chain'
    },
    {
        chainId: 128,
        disabled: false,
        src: "/images/coins/HECO.svg",
        chainName: 'Heco'
    }
]

const SwapIcon = () => {
    return (<svg style={{ position: 'relative', zIndex: 1 }} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="20" fill="#F1EEE6" />
        <path d="M20 13V27" stroke="#4D3C55" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M27 20L20 27L13 20" stroke="#4D3C55" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

    )
}

const StyledSeparator = styled(Separator)`
    margin: 12px 0;
    background-color: rgba(81, 81, 92, 0.1); 
`

export default function Bridge({ history }) {
    const [ifSelectChainOpen, setSelectChain] = useState(true)
    const [bridgePercentage, setBridgePercentage] = useState(0)
    const { account } = useActiveWeb3React()
    const [confirmedValue, setConfirmedValue]: any = useState(null)
    const [transactionHash, setTransaction] = useState('')

    const { currencies, v2Trade, currencyBalances, parsedAmount, inputError: swapInputError } = useDerivedBridgeInfo()

    // modal and loading
    const [{ showProcess, showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
        showProcess: boolean
        showConfirm: boolean
        tradeToConfirm: Trade | undefined
        attemptingTxn: boolean
        swapErrorMessage: string | undefined
        txHash: string | undefined
    }>({
        showProcess: false,
        showConfirm: false,
        tradeToConfirm: undefined,
        attemptingTxn: false,
        swapErrorMessage: undefined,
        txHash: undefined
    })

    useEffect(() => {
        if (account && showProcess) {
            const avaxConnection = new WebSocket(ws)

            avaxConnection.onopen = (e) => {
                avaxConnection.send(JSON.stringify(
                    { "id": 1, "method": "eth_subscribe", "params": ["logs", { "address": tokenBridgeList.hct[43114], "topics": ['0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'] }] }
                ))
            }

            avaxConnection.onmessage = (e) => {
                const _ = JSON.parse(e?.data)

                if (_?.params) {
                    const res = _?.params?.result
                    const topics = _?.params?.result?.topics
                    if (res && topics && topics[1] && topics[2]) {
                        const ifRightSender = topics[1].includes(minterAddress.toLowerCase().slice(2))
                        const ifRightRecipient = topics[2].includes(account.toLowerCase().slice(2))
                        const value = parseInt(res?.data, 16)
                        // @ts-ignore
                        const amountIn = parsedAmount ? parsedAmount.raw.toString() : 0
                        const ifRightValue = +amountIn - value - 10 * 10 ** 18
                        if (ifRightSender && ifRightRecipient && !ifRightValue) {
                            setBridgePercentage(100)
                            setTransaction(res?.transactionHash)
                            avaxConnection.close()
                        }
                    }
                }
            }
            avaxConnection.onerror = (e) => {
               // console.log('onerror', e)
            }

            return () => {
                avaxConnection.close()
            }
        }
        return () => null
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, showProcess])

    const closeSelectChain = () => {
        setSelectChain(false)
    }

    const openSelectChain = () => {
        setSelectChain(true)
    }

    const onDismiss = () => {
        return closeSelectChain()
    }

    const { chainId } = useActiveWeb3React()

    // @ts-ignore
    const currencyA: any = useCurrencyWithAllChains(tokenBridgeList.hct[chainId])
    // @ts-ignore
    const currencyB: any = useCurrencyWithAllChains(tokenBridgeList.hct[43114], 43114)

    const [isExpertMode] = useExpertModeManager()
    // swap state
    const { independentField, typedValue, recipient } = useSwapState()

    const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
        currencies[Field.INPUT],
        currencyB,
        typedValue
    )
    const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
    const trade = v2Trade

    const parsedAmounts = useMemo(() => {
        return showWrap
            ? ({
                [Field.INPUT]: parsedAmount,
                [Field.OUTPUT]: parsedAmount
            })
            : ({
                [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
                [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
            })
    }, [showWrap, parsedAmount, trade, independentField])

    const { onCurrencySelection, onUserInput } = useSwapActionHandlers()
    const isValid = !swapInputError

    const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

    const handleTypeInput = useCallback(
        (value: string) => {
            onUserInput(Field.INPUT, value)
        },
        [onUserInput]
    )

    const formattedAmounts = {
        [independentField]: typedValue,
        [dependentField]: showWrap
            ? parsedAmounts[independentField]?.toExact() ?? ''
            : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
    }

    const [toasts, setToasts]: any = useState([]);
    const onRemoveToast = () => { setToasts([]) }
    const tokenContract = useTokenContract(currencyA?.address)
    const [waitFinished, setWaitFinished] = useState(false)

    useEffect(() => {
        if (waitFinished && bridgePercentage < 50) {
            setBridgePercentage(50)
        }
    }, [bridgePercentage, waitFinished])

    const transferToBlackHole = useCallback(
        async () => {
            if (tokenContract && parsedAmounts && parsedAmounts[Field.INPUT]) {
                try {
                    const bigNumberAmountIn = parsedAmounts[Field.INPUT]?.raw.toString()

                    setConfirmedValue(bigNumberAmountIn)
                    // @ts-ignore
                    const res = await tokenContract.transfer(blackHoleAddress, bigNumberAmountIn)
                    setTransaction('')
                    setBridgePercentage(0)
                    setWaitFinished(false)
                    setSwapState((prevState) => ({ ...prevState, showConfirm: false, showProcess: true }))
                    await new Promise(resolve => {
                        setTimeout(() => {
                            if (bridgePercentage < 25) {
                                setBridgePercentage(25)
                            }
                            resolve('done')
                        }, 800)
                    })

                    const t = await res.wait()
                    if (t.status === 1) {
                        setWaitFinished(true)
                    }
                } catch (e: any) {
                    const errorToast = {
                        id: `id-${e?.data?.code}`,
                        title: `Error`,
                        description: (
                            <AutoColumn>
                                <Text mt='10px' mb='10px'>{e?.data?.message ?? e?.message}</Text>
                            </AutoColumn>
                        ),
                        type: 'danger',
                    };

                    setToasts([errorToast])
                    console.error('error', e)
                }
            }
        }, [tokenContract, parsedAmounts, bridgePercentage, setBridgePercentage])

    const handleConfirmDismiss = useCallback(() => {
        setSwapState((prevState) => ({ ...prevState, showConfirm: false, showProcess: false }))
        setBridgePercentage(0)
        setTransaction('')
        setWaitFinished(false)
        // if there was a tx hash, we want to clear the input
        if (txHash) {
            onUserInput(Field.INPUT, '')
        }
    }, [onUserInput, txHash, setSwapState])

    const handleInputSelect = useCallback(
        (inputCurrency) => {
            onCurrencySelection(Field.INPUT, inputCurrency)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onCurrencySelection]
    )

    const handleOutputSelect = useCallback(
        (outputCurrency) => {
            onCurrencySelection(Field.OUTPUT, outputCurrency)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onCurrencySelection]
    )

    useEffect(() => {
        if (currencyA && currencyB) {
            handleInputSelect(currencyA)
        }
    }, [handleInputSelect, handleOutputSelect, currencyA, currencyB])

    const _height = useMemo(() => {
        if (showConfirm) {
            return '512px'
        }
        if (showProcess) {
            return '355px'
        }
        return undefined
    }, [showConfirm, showProcess])

    useEffect(() => {
        if (chainId !== 56 && chainId !== 128) {
            handleConfirmDismiss()
            openSelectChain()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId])

    const maxAmountInput: any = maxAmountSpend(currencyBalances[Field.INPUT])
    const handleMaxInput = useCallback(() => {
        if (maxAmountInput) {
            onUserInput(Field.INPUT, maxAmountInput.toExact())
        }
    }, [maxAmountInput, onUserInput])

    return (
        <>
            <div id="bridge" >
                <ChooseChain noNeedClose chainConfig={defaultChainConfig} onDismiss={onDismiss} isOpen={ifSelectChainOpen} portalId="bridge" />
            </div>

            <AppBody>
                <Wrapper height={_height} id="bridge-page">
                    <ConfirmSwapModal
                        account={account}
                        parsedAmounts={parsedAmounts}
                        isOpen={showConfirm}
                        trade={trade}
                        originalTrade={tradeToConfirm}
                        onAcceptChanges={() => null}
                        attemptingTxn={attemptingTxn}
                        txHash={txHash}
                        recipient={recipient}
                        allowedSlippage={0}
                        onConfirm={transferToBlackHole}
                        swapErrorMessage={swapErrorMessage}
                        onDismiss={handleConfirmDismiss}
                    />
                    <ProcessModal
                        transactionHash={transactionHash}
                        bridgeStatus={bridgePercentage}
                        account={account}
                        parsedAmounts={parsedAmounts}
                        isOpen={showProcess}
                        trade={trade}
                        originalTrade={tradeToConfirm}
                        onAcceptChanges={() => null}
                        attemptingTxn={attemptingTxn}
                        txHash={txHash}
                        recipient={recipient}
                        allowedSlippage={0}
                        onConfirm={transferToBlackHole}
                        swapErrorMessage={swapErrorMessage}
                        onDismiss={handleConfirmDismiss} />



                    {/* <PageHeader title="Bridge" showSettings={false} /> */}


                    <Flex p='27px 29px' justifyContent='space-between' alignItems='center'>
                        <FiArrowLeft cursor='pointer' onClick={openSelectChain} size='24px' />
                        <Flex width='100%' justifyContent='center' flex={1}><Text textAlign='center' fontSize='20px'>HCT Bridge</Text></Flex>
                        {/* <FiClock size='24px' /> */}
                    </Flex>


                    <CardBody p='24px 24px 48px'>
                        <AutoColumn gap="md">

                            <CurrencyInputPanel
                                portalId="bridge-page"
                                dir="From"
                                label={
                                    independentField === Field.OUTPUT && !showWrap && trade
                                        ? 'From (estimated)'
                                        : TranslateString(76, 'Swap from')
                                }
                                disableCurrencySelect={Boolean(true)}
                                value={formattedAmounts[Field.INPUT]}
                                showMaxButton
                                onMax={handleMaxInput}
                                currency={currencies[Field.INPUT]}
                                onUserInput={handleTypeInput}
                                onCurrencySelect={handleInputSelect}
                                otherCurrency={currencies[Field.OUTPUT]}
                                id="bridge-currency-input"
                            />

                            <AutoColumn justify="space-between">
                                <AutoRow justify={isExpertMode ? 'space-between' : 'flex-end'}>
                                    {/* eslint-disable-next-line */}
                                    <IconDecoration />

                                    <ArrowWrapper clickable={false}>
                                        <SwapIcon />
                                    </ArrowWrapper>
                                </AutoRow>
                            </AutoColumn>

                            <CurrencyInputPanel
                                defaultBalanceValue=' '
                                portalId="bridge-page"
                                hideBalance
                                disableCurrencySelect={Boolean(true)}
                                value={formattedAmounts[Field.INPUT]}
                                onUserInput={handleTypeInput}
                                dir="To"
                                label={
                                    independentField === Field.INPUT && !showWrap && trade ? '' : TranslateString(80, '')
                                }
                                showMaxButton={false}
                                currency={currencyB}
                                onCurrencySelect={handleOutputSelect}
                                otherCurrency={currencies[Field.INPUT]}
                                id="bridge-currency-output"
                            />
                        </AutoColumn>
                        <StyledSeparator />
                        <Text mb='32px' color='#8A7F90'>Bridge Fee: 10 HCT</Text>

                        <BottomGrouping>
                            {!account ? (
                                <ConnectWalletButton />
                            ) : (
                                <Button
                                    onClick={() => {
                                        setSwapState({
                                            tradeToConfirm: trade,
                                            attemptingTxn: false,
                                            swapErrorMessage: undefined,
                                            showConfirm: true,
                                            txHash: undefined,
                                            showProcess: false
                                        })
                                    }}
                                    id="bridge-button"
                                    disabled={!isValid || !(+(currencyBalances[Field.INPUT]?.toSignificant(6) ?? 0) > 0)}
                                    variant={!isValid ? 'danger' : 'tertiary'}
                                    width='100%'
                                >
                                    {
                                        (!currencyBalances || !(+(currencyBalances[Field.INPUT]?.toSignificant(6) ?? 0) > 0))
                                            ? <Dots>Loading</Dots>
                                            : (
                                                <TranslatedText translationId={100}>
                                                    {swapInputError || 'Next'}
                                                </TranslatedText>
                                            )
                                    }
                                </Button>
                            )}
                            {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                        </BottomGrouping>
                    </CardBody>
                </Wrapper>

            </AppBody>

            <ToastContainer toasts={toasts} onRemove={onRemoveToast} />

            {
                !showConfirm ? <AdvancedSwapDetailsDropdown trade={trade} /> : null
            }
        </>
    )
}
