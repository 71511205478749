// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import {
  Button,
  Text,
  Flex,
  HelpLinkCard,
  MessageLinkCard,
  HomeArrowIcon,
  OptionCard,
  Skeleton,
  TwitterIcon,
  TelegramIcon,
  MediumIcon,
  LinkExternal
} from '@hurricaneswap/uikit'
import axios from "axios"
import { Link, useHistory } from 'react-router-dom'
import CoinToShow from './config'
import { helps, messages } from './config'
import HomePageFooter from '../../components/HomePageFooter'
import useTheme from '../../hooks/useTheme'
import { useTranslation } from 'react-i18next'
import { SafeIcon, FastIcon, SimpleIcon } from './Icons'
import i18next from '../../i18n'
import useMobile from '../../hooks/useMobile'
import useHctUsdtBalance from 'hooks/useHctUsdtBalance'
import PeckshieldIcon from '../../assets/imgs/peckShield.svg'
import { clippingParents } from '@popperjs/core'
import Invester from './invester'
import useRequest from '@ahooksjs/use-request'
import { couldStartTrivia, isConstructorDeclaration } from 'typescript'

const Wrapper = styled.div<{ ifMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:100%;
  font-size: ${({ ifMobile }) => (ifMobile ? '14px' : '16px')};
`
const FlexSection = styled(Wrapper)`
  font-size: ${({ ifMobile }) => (ifMobile ? '14px' : '16px')};
`

const Title = styled(Text)`
  font-family: GTTextBold;
  font-size: 2.5em;
  line-height: 46px;
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.text};
`

const IconContainer = styled.div`
  width: 47.83px;
  height: 47.83px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items:center;
  justify-content: center;
`
const CardWrapper = styled.div`
  >div{
    width: 100%;
    max-width: 490px;
    margin-top: 10px;
  }
  margin: auto;
  width: 100%;
  max-width: 1000px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 490px);
  grid-gap: 20px;
  @media (max-width: 522px) {
      grid-template-columns: repeat(auto-fill, 100%);
  
  }
`

const GridContainer = styled.div<{ ifMobile: boolean }>`
  display: grid;
  justify-content: center;
  grid-template-columns: ${(ifMobile => (ifMobile ? '1fr 1fr 1fr' : 'repeat(auto-fill, 300px)'))};
  grid-gap: 20px;
`

const CoinCardWrapper = styled.div<{ ifMobile: boolean }>`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(auto, 230px));
  grid-gap: 35px 20px;
  width: 100%;
  max-width: 100%;
  margin-bottom: ${({ ifMobile }) => (ifMobile ? '30px' : '136px')};
  
  > div{
    width: 100%;
    max-width: 230px; 
  
  }
  
  @media (max-width: 510px) {
      grid-template-columns: repeat(2, 1fr);
      >div{
        width: 100%;
        height: 210px;
        img{
            transform: scale(0.7);
            transform-origin: bottom right;
        }
      }
      
  }
  
  @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      >div{
        width: 100%;
      }
  }
`

const GridWrapper = styled(Flex) <{ ifMobile: boolean }>`
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(auto, 320px));
  grid-gap: ${({ ifMobile }) => (ifMobile ? '15px' : '20px')};;
 `

const DataCard = styled.div<{ ifMobile: boolean, ifNarrow: boolean }>`
  position:relative;
  background: ${({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.08)' : 'rgba(255, 255, 255, 0.5)'};
  border: 1px solid ${({ theme }) => theme.isDark ? 'rgba(77, 60, 85, 0.12)' : '#F1EDFF'}; 
  backdrop-filter: blur(50px);
  border-radius: 24px;
  // max-width: 320px;
  width: ${({ ifNarrow }) => ifNarrow ? '182px' : '100%'};
  height: 166px;
  padding:32px;
  font-family: GTText;
  & span{
    color:inherit;
    font-family: GTText;
    font-style: normal;
    font-weight: bold;
    line-height: 32px;
    margin-right:8px;
    margin-left:-3px
  }
  & div{
    margin-bottom:10px
  }
  div:first-child
  { 
    font-weight: 500;
    line-height: 22px;
    font-family: GTText;
  }
  div:last-child
  { 
    font-family: GTText;
    font-weight: normal;
    line-height: 22px;
    color: ${({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.44)' : 'rgba(77, 60, 85, 0.65)'};
    margin-bottom:0px

  }
  #px {
    font-size: 1em; 
  }
  
  ${({ ifMobile }) => (ifMobile && css`grid-area: 2 / 1 / 2 / 3;`)}
  
  
`
const WrapOptionCard = styled(OptionCard)`
  &.dynamic:hover {
    transform: scale(0.98);
    img{
        transform: scale(1.05)
    }
}
`

const Info = styled.div`
margin-top: 10px;
margin-bottom: 10px;
text-align: center;
//--progress:0.5;
width:100%;
max-width: 300px;
display:flex;
flex-direction:column;
align-items:center;
margin-top:40px;
transition:all 0.5s ;
:hover{
  transform:scale(1.2)
 // animation-duration: 5s;
 // animation-name: swashIn;
  //animation-iteration-count: infinite
}

@keyframes swashIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  90% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
/*@keyframes swashIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(-90deg);
    transform: perspective(800px) rotateX(-90deg);
  }
  50% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(50deg);
    transform: perspective(800px) rotateX(50deg);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(0deg);
    transform: perspective(800px) rotateX(0deg);
  }
}*/
   

/*transition: 0.4s transform ease-in-out; 
 transform:scale(calc(1.8 - (0.8 * var(--progress)))) translateY(calc(60px * (1 - var(--progress))));
 opacity:var(--progress); */

:last-of-type{
margin-bottom:80px;
}

`
const Ps = styled.div<{ ifMobile: boolean }>`
  :hover{
    section{
        // background: rgba(141, 128, 213, 0.1);
    }
    .audit span{
        border-bottom: 1px solid #5723A6;
    }
    
  }
  
  section{
    transition: all linear 0.2s;
  }
  cursor: pointer;
  width:100vw;
  //height:248px;
  
  display: flex;
  align-items: center;
  
  // background:${({ theme }) => theme.isDark ? 'rgba(77, 60, 85, 0.25)' : 'rgba(241, 237, 255, 0.4)'};
  section:first-child{
    width:1000px;
    margin:0 auto;
    position:relative
  }
  margin-top:  ${({ ifMobile }) => (ifMobile ? '48px' : '0px')};
  margin-bottom:  ${({ ifMobile }) => (ifMobile ? '32px' : '64px')};

`
const Socialflex = styled(Flex)`
 width:100vw;
 justify-content:left;
 margin-top:60px;
 svg {
   margin-left:20px
 }
`
const Background = styled.div`
 width:100vw;
 background:${({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(241, 237, 255, 0.4)'};
 padding:64px;
`
const Text1 = styled(Text) <{ ifMobile: Boolean }>`
  font-size: 0.875em;
  line-height: 16px;
  letter-spacing: 0.5px;
  width: 100%;
  max-width:588px;
  min-width: ${({ ifMobile }) => (ifMobile ? '100%' : '519px')};
  text-align: ${({ ifMobile }) => (ifMobile ? 'center' : 'left')};
  flex: 1;
  margin-right:80px;
  margin-bottom: 20px;
  color:${({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.65)' : 'rgba(77, 60, 85, 0.85)'};
  
  @media (max-width: 1000px) {
    margin-right:0; 
  }
`

const Privacy = styled.div`
margin-top: 24px;
font-family: GTText;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
color: #C7C8D9;
margin-left:20px
`

const PsImg = styled.img<{ width: String }>`
  width: ${({ width }) => (width)};
`
const LinkWithHover = styled.a`
  width: 100%;
  
  .audit span{
    transition: all linear 0.2s;
  }
`

const InvestLinkWithHover = styled.a`
    transition: all linear 0.2s;
    filter: ${({ theme }) => (theme.isDark ? 'contrast(0.6) opacity(0.8)' : ' opacity(0.6) ')};
  // .rectFill{
    // fill: #8D80D5;
    // color: #8D80D5;
    // transition: all linear 0.2s;
  // }
  :hover{
      filter: ${({ theme }) => (theme.isDark ? 'contrast(0.3) opacity(1)' : ' opacity(1) ')};
    // .rectFill{
      // fill: #6425C5;
      // color: #6425C5;
    // }
  }
`

const Triangle = styled.div<{ color: String; up: Boolean }>`
  width: 0;
  height: 0;
  ${({ up }) => up ? 'border-bottom: 10px solid #4BDD97' : 'border-top: 10px solid #FC5A94'};
  //border-top: 10px solid ${({ color }) => (color)};
  //border-bottom: 10px solid ${({ color }) => (color)};
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left:6px
`
const StyledLinkExternal = styled(LinkExternal)`
  color: #5B8DEF;
  svg{
    fill: #5B8DEF;
  }
`

const initialValue = {
  // hct
  ['0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a']: {
    price: '',
    arp: ''
  },
  // avax
  ['0x66f05c9882535569a29ab045c2e7776266ab5606']: {
    price: '',
    arp: ''
  },
  // usdt.e
  ['0xC40f16B3DF9b2E11f48B11A535ad9ab55b7dbF67']: {
    price: '',
    arp: ''
  },
  // weth.e
  ['0x7961192cb1994f8cf9562e745dc3045e6bcddaea']: {
    price: '',
    arp: ''
  },
  // cake.e
  ['0xBFCdA28A78d74E78CE06cABDC0b27124AC5f4268']: {
    price: '',
    arp: ''
  },
  // mdx
  ['0x98556f4bf9657Ec3b42b703405be4292D627aDfD']: {
    price: '',
    arp: ''
  },
  // xvs
  ['0x4B16917b198A22cFa875A08ebe359824874A9c28']: {
    price: '',
    arp: ''
  },
  // bnb
  ['0x31EF1216757e33490D33183b5592CCc2E4b74E01']: {
    price: '',
    arp: ''
  }
}

const fetchTokensBody = "{\n  \"query\": \"{\\n  tokens{\\n   id\\n   symbol\\n   derivedBNB\\n    derivedUSD\\n  }\\n}\",\n  \"variables\": null\n}";
const fetchVolumeBody = "{\n  \"query\": \"{\\n  pancakeFactories(first: 5){\\n   totalVolumeUSD\\n }\\n}\",\n  \"variables\": null\n}"

const Home = () => {
  const theme = useTheme()
  const backgroundcolor = theme.isDark ? 'linear-gradient(90deg, #8D80D5 0%, #A37CDD 91.07%)' : 'linear-gradient(90deg, #5723A6 0%, #8D80D5 91.07%)'
  const CoinID = { 1027: 'eth', 7186: 'cake', 7083: 'uni', 8983: 'eps', 4512: 'link', 8335: 'mdx', 5805: 'avax' }
  const { t } = useTranslation()
  const ifMobile = useMobile()
  const ifMobileForPeckShield = useMobile(1000)
  const ifMobileForNav = useMobile(1200)
  const { push } = useHistory()
  // const [coinData, setCoinData] = useState(window.localStorage.getItem('cards') && JSON.parse(window.localStorage.getItem('cards')).hct ? JSON.parse(window.localStorage.getItem('cards')) : initialValue)
  const [coinData, setCoinData] = useState(initialValue)
  const [helpTrans, setHelpTrans] = useState(helps)
  const [messageTrans, setMessageTrans] = useState(messages)
  const [allottedValue, setAllottedValue] = useState(0)
  const [tvl, setTvl] = useState(0)
  const { run: fetchTokens, data: tokenData } = useRequest(
    () => fetch("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange-v2-fuji", {
      method: 'POST',
      body: fetchTokensBody,
      variables: null
    }), { manual: true, pollingInterval: 5000 })

  const { run: fetchVolume, data: volumeData } = useRequest(
    () => fetch("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange-v2-fuji", {
      method: 'POST',
      body: fetchVolumeBody,
      variables: null
    }), { manual: true, pollingInterval: 5000 })

  const handleTokenValue = async (data) => {
    const res = await data.text()
    const tokens = JSON.parse(res)?.data?.tokens
    console.log('shuju ',tokens)
    setCoinData(old => {
      const _ = JSON.parse(JSON.stringify(old))
      console.log(_)
      Object.keys(_).map((item, index) => {
        const __ = tokens.filter(i => {
          if(i?.id.toUpperCase() ==='0x7961192CB1994f8cF9562e745Dc3045e6BCdDAE'){
            console.log(i)
          }
          return item.toLowerCase() == i?.id.toLowerCase()
        })
        if (__.length > 0) {
          _[item].price = Number(__[0]?.derivedUSD).toFixed(3)
        }
      })

      return _
    })
  }

  const handleVolumeValue = async (data) => {
    // const res = await data.text()
    // console.log(data)
    // volume总量
    //  const volume = JSON.parse(res)?.data?.pancakeDayDatas[0]?.dailyVolumeUSD
    //  setAllottedValue(Number(volume).toFixed())
  }
  const getTotalValue = async () => {
    // const res = await axios.post("https://api.thegraph.com/subgraphs/name/peratx/hurricane-exchange-subgraph-fuji", {
    const res = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange-v2-fuji", {
      query: `{
        pancakeFactories(first: 5) {
          totalVolumeUSD
        }
      }`,
      variables: null
    });

    if (res?.data?.data?.pancakeFactories) {
      const result = res?.data?.data?.pancakeFactories[0]['totalVolumeUSD'] * 0.001 * 0.9
      // console.log(res?.data?.data?.pancakeFactories[0]['totalVolumeUSD'],'2222')

      setAllottedValue(Number(result).toFixed(2))

    }
  };

  const getTVL = async () => {
    // const res = await axios.post("https://api.thegraph.com/subgraphs/name/peratx/hurricane-exchange-subgraph-fuji", {
    const res = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange-v2-fuji", {
      query: `{
        pancakeFactories(first: 5) {
          id
          totalLiquidityUSD
        }
      }`,

      variables: null
    });


    const singelToken = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/farm", {
      query: `{
        pools(where: {id: "0"}){
          entryUSD
          exitUSD
        }
      }
      `,
      variables: null
    });


    if (res?.data?.data?.pancakeFactories) {
      const pairLq = res?.data?.data?.pancakeFactories[0]['totalLiquidityUSD']
      // console.log(result)

      const SingleFarm = singelToken?.data?.data?.pools[0]['entryUSD'] - singelToken?.data?.data?.pools[0]['exitUSD']
      
      const totaltvl = Number(pairLq) 
      console.log(totaltvl)
      setTvl(parseInt(Number(totaltvl)).toLocaleString())


      //  console.log('res',res)
    }
  };


  useEffect(() => {
    fetchTokens()
    fetchVolume()
    getTotalValue()
    getTVL()
  }, [])

  useEffect(() => {
    if (tokenData) {
      handleTokenValue(tokenData)
    }
  }, [tokenData])

  useEffect(() => {
    if (volumeData) {
      handleVolumeValue(volumeData)
    }
  }, [volumeData])

  useEffect(() => {
    const helpTrans = helps.map((help) => ({ label: t(help['label']), href: help['href'] }))
    const messageTrans = messages.map((message) => ({ label: t(message['label']), href: message['href'] }))
    const data = _.cloneDeep(helpTrans)
    const data1 = _.cloneDeep(messageTrans)
    setHelpTrans(data)
    setMessageTrans(data1)
  }, [i18next.languages])

  const routerToSwap = (coin) => {

    if (coin.title == 'avax') {
      push(`/swap`)
    } else {
      push(`/swap?inputCurrency=${coin.address}`)
    }
  }

  return (
    <Wrapper id='wrapper' ifMobile={ifMobile}>
      <FlexSection ifMobile={ifMobile}>
        <Title>HurricaneSwap</Title>
        <Text textAlign='center' mb={20} fontSize='1.5em' fontWeight='400' maxWidth='642px' width='100vw'>
          {t('homePageSlogan')}
        </Text>
        <Button as={Link} to='/swap' style={{
          justifyContent: 'space-between',
          fontSize: '1.75em',
          height: '80px',
          width: '280px',
          boxShadow: '0px 14px 60px rgba(139, 70, 182, 0.4)',
          borderRadius: '68px',
          background: backgroundcolor,
          textAlign: 'center'
        }}>{t('StartTrading')}
          <IconContainer><HomeArrowIcon width='40px' /></IconContainer>
        </Button>

        <StyledLinkExternal href="https://test.hurricaneswap.com/" mt='40px' mb={ifMobile ? '50px' : '122px'} >Go testnet V1</StyledLinkExternal>



      </FlexSection>
      <section style={{ marginBottom: '60px', width: '100%' }}>
        <GridWrapper ifMobile={ifMobile}>
          <DataCard ifNarrow={ifMobile}>
            <Text fontSize={ifMobile ? '16px' : '18px'}>{t('Total Distributed')}</Text>
            <Text fontSize={ifMobile ? '17px' : '28px'}><span> 0</span>HCT</Text>
            <Text fontSize={ifMobile ? '16px' : '18px'}>≈ --- USDT</Text>
          </DataCard>
          <DataCard ifNarrow={ifMobile}>
            <Text fontSize={ifMobile ? '16px' : '18px'}>{t('To be allotted')}</Text>
            <Text fontSize={ifMobile ? '17px' : '28px'}><span> {(parseInt(allottedValue / coinData['0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a']?.price).toLocaleString()) || '---'} </span>HCT</Text>
            <Text fontSize={ifMobile ? '15px' : '18px'}>≈ {(parseInt(allottedValue).toLocaleString()) || '---'} USDT</Text>
          </DataCard>
          <DataCard ifMobile={ifMobileForNav}>
            <Text fontSize={ifMobile ? '16px' : '18px'}>{t('Total Value Locked')}</Text>
            <Text fontSize={ifMobile ? '17px' : '28px'}><span>{(tvl) || '---'}</span>USDT</Text>
            <Text id='px' fontSize={ifMobile ? '15px' : '18px'}>{t('Covers HurricaneSwap')}</Text>
          </DataCard>
        </GridWrapper>
      </section>

      <section style={{ width: '100%', maxWidth: '1000px' }}>

        <CoinCardWrapper ifMobile={ifMobile}>
          {CoinToShow.map((coin) => (
            <WrapOptionCard onClick={() => {
              routerToSwap(coin)
            }} key={coin.currency} chain={coin.chain}
              currency={coin.currency} cardColor={coin.color} className={ifMobile ? '' : 'dynamic'}>
              <Text fontSize='1.25em!important'>{coin.title.toUpperCase()}<span>{coin.subtitle}</span></Text>

                {(coinData[coin.contract] && coinData[coin.contract].price)
                ? (<Text fontSize='1.5em!important'>${coinData[coin.contract].price}</Text>)
                : (<Skeleton animation="pulse" variant="rect" width="60%" height="32px" />)}
            {/* {(coinData[coin.title] && coinData[coin.title].arp)
                ? (
                  <Flex alignItems='center'>
                    <Text fontSize='1em!important'>{coinData[coin.title].arp} % </Text>
                    <Triangle up={coinData[coin.title].arp > 0 ? true : false} />
                  </Flex>
                )
                : (<Skeleton animation="pulse" variant="rect" width="30%" height="22px" />)}  */}

            </WrapOptionCard>
          ))}
        </CoinCardWrapper>
      </section>

      <section style={{ width: '100%', maxWidth: '1000px' }}>
        <Title style={{ textAlign: ifMobile ? 'center' : 'center' }}>{t('Why HurricaneSwap')}</Title>
        <GridContainer id='msg' ifMobile={ifMobile}>
          <Info className={ifMobile ? 'info swashIn' : 'info'}>
            <SimpleIcon />
            <Text mt={1} fontSize='20px' fontWeight='400'>{t('Easier')}</Text>
            <Text>{t('Add liquidity in 2 steps, 15 seconds')}</Text>
          </Info>
          <Info className={ifMobile ? 'info swashIn' : 'info'}>
            <SafeIcon />
            <Text mt={1} fontSize='20px' fontWeight='400'>{t('Safer')}</Text>
            <Text>{t('Avoid the risk of money laundering')}</Text>
          </Info>
          <Info className={ifMobile ? 'info swashIn' : 'info'}>
            <FastIcon />
            <Text mt={1} fontSize='20px' fontWeight='400'>{t('Cheaper')}</Text>
            <Text>{t('0.2% cross-chain fee, lower slippage')}</Text>
          </Info>
        </GridContainer>



      </section>

      <Ps ifMobile={ifMobile}>
        <LinkWithHover
          target='_blank'
          href="https://github.com/HurricaneSam/AuditReport/blob/main/PeckShield-Audit-Report-HurricanSwapV2-v1.0rc.pdf">
          <section style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                margin: 'auto',
                maxWidth: '1000px',
                width: '100%',
                paddingLeft: ifMobileForPeckShield ? '16px' : '0',
                paddingRight: ifMobileForPeckShield ? '16px' : '0'
              }}>
              {ifMobileForPeckShield
                ? (
                  <Flex flexDirection='column' justifyContent='space-between' alignItems='center'>
                    <Flex mb='16px' flexDirection='row' justifyContent='space-between' alignItems='center'>

                      <Flex flexDirection='column'>
                        <PsImg width={ifMobileForPeckShield ? '218px' : '343px'} src={PeckshieldIcon} />
                        <Text className='audit' color='#5723A6' width='100%'
                          textAlign={ifMobileForPeckShield ? 'center' : 'right'}>
                          <span>{t('See Auditing Report')}</span> </Text>
                      </Flex>
                    </Flex>
                    <Text1 ifMobile={ifMobileForPeckShield}>{t('Packshield introduce')}</Text1>
                  </Flex>
                )
                : (
                  <>
                    <Flex alignItems='center' mt='20px' mb='20px' justifyContent='space-between' flexWrap='wrap'>
                      <Text1 ifMobile={ifMobileForPeckShield}>{t('Packshield introduce')}</Text1>
                      <Flex mb='20px' alignItems='center'>
                        <Flex flexDirection='column'>
                          <PsImg width={ifMobileForPeckShield ? '218px' : '343px'} src={PeckshieldIcon} />
                          <Text className='audit' color='#5723A6' width='100%'
                            textAlign={ifMobileForPeckShield ? 'center' : 'right'}>
                            <span>{t('See Auditing Report')}</span> </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </>
                )
              }
            </div>


          </section>
        </LinkWithHover>
      </Ps>

      <Invester />
      {!ifMobile && (
        <Background >
          {/* <Title style={ { textAlign: ifMobile ? 'center' : 'left' } }>{ t ('Need help with something?') }</Title> */}
          <CardWrapper>
            <HelpLinkCard title={t('Help')} helps={helpTrans} style={{ maxWidth: '490px', width: '100%' }}
              isDark={theme.isDark} />
            <MessageLinkCard title={t('Announcement')} helps={messageTrans}
              style={{ maxWidth: '490px', width: '100%' }} isDark={theme.isDark} />
          </CardWrapper>
        </Background>
      )
      }


      {ifMobile && (
        <Flex flexDirection='column'>
          <Socialflex>
            <a href="https://twitter.com/hurricaneswap"><TwitterIcon /></a>
            <a href="https://t.me/HurricaneSwap"><TelegramIcon /></a>
            <a href="https://hurricaneswap.medium.com"><MediumIcon /></a>
          </Socialflex>
          <Privacy>
            © 2021 HurricaneSwap
          </Privacy>
        </Flex>

      )}


      {

        !ifMobile && (<HomePageFooter />)
      }
    </Wrapper>
  )
}

export default Home
