import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Flex, Text, Button } from '@hurricaneswap/uikit'
import useMobile from '../../hooks/useMobile'
import coinGroup from '../../assets/imgs/coinGroup.png'



const CardContainer = styled.div<{ifMobile: boolean}>`
  max-width: 710px;
  width: 100%;
  padding: ${({ifMobile})=>(ifMobile ? '0 10px' : '0')};
  box-sizing: border-box;
  min-height: 285px;
  overflow: hidden;
  position: relative;
  background: ${({theme})=>(theme.colors.swapContainer)};
  border:${ ({ theme }) => (theme.isDark ? '1px solid #ffffff47' : 'none') }; 
  border-radius: 20px;
`

const Card = styled.div<{ifMobile?: boolean}>`
  width: 100%;
  height: 100%;
  // padding: 35px 5px 0 26px;
  padding: 5px 5px 20px;
  // background-image: url(${({ifMobile})=>(!ifMobile ? coinGroup : null)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

`

const Circle = styled.div`
  max-width: 332px;
  max-height: 332px;
  position: relative;
  ::after{
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid black;  
  }
`

const titile = 'What\'s HurricaneStation'
const des1 = 'Aggregate liquidity sources across leading DEXs.'
const des2 = 'Discover the most efficient trading routes.'
const des3 = 'Trade safely with no limits and hidden fees.'

const StationDes = () => {
  const ifMobile = useMobile()
  const {t} = useTranslation()

  return (
    <CardContainer ifMobile={ifMobile}>
      <Card ifMobile={ifMobile}>
        <Flex flexDirection='row' justifyContent='space-between' flexWrap='wrap' width='95%'>
        <Flex >
          <Flex alignItems={ifMobile?'center':'flex-start'} flexDirection='column' pt='30px' pl='21px' pr='21px' maxWidth='322px' style={{boxSizing: 'content-box'}}>
            <Text mb='20px' fontSize='24px' >{titile}</Text>
            <Text textAlign={ifMobile?'center':'left'} mb='10px' lineHeight='30px' fontSize='15px' color='#67586E'>{t(des1)}</Text>
            <Text textAlign={ifMobile?'center':'left'} mb='10px' lineHeight='30px' fontSize='15px' color='#67586E'>{t(des2)}</Text>
            <Text textAlign={ifMobile?'center':'left'} mb='10px' lineHeight='30px' fontSize='15px' color='#67586E'>{t(des3)}</Text>
          </Flex>
        </Flex>
        <Flex flexDirection='column' alignItems={ifMobile ? 'center' : ''} width={ifMobile ? '100%' : ''} mt={ifMobile?'20px':'80px'}>
          <Button variant="secondary" width="200px" height="48px" style={{fontSize:'16px',marginBottom:'12px'}}><a href='https://hurricaneswap.medium.com/launch-of-hurricaneswap-testnet-v2-station-tutorial-633392c2a120
' rel="noreferrer" target='_blank'>Tutorial</a></Button>
          <Button variant="secondary" width="200px" height="48px" style={{fontSize:'16px',marginBottom:'12px'}}><a href='https://testnet.binance.org/faucet-smart' rel="noreferrer" target='_blank'>BNB faucet</a></Button>
          <Button variant="secondary" width="200px" height="48px" style={{fontSize:'16px'}}><a href='https://bsc-testswap.vercel.app/swap' rel="noreferrer" target='_blank'>BSC swap</a></Button>
        </Flex>
        </Flex>
      </Card>
    </CardContainer>

  )
}

export default StationDes
